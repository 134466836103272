<template>
    <ion-app :scroll-events="true" class="profile">
        <ion-page>
            <div class="header-step">
                <Header />
            </div>

            <ion-content class="profile">
                <div class="content">
                    <!-- <Step /> -->

                    <div class="ion-padding">
                        <div class="cont-updated-account">
                            <div class="container">
                                <div class="box">
                                    <h2 class="title-secondary">{{ getMyLanguage("client-space", "client-space.account-success.title") }}</h2>

                                    <img src="~@/assets/images/icons/register-success.svg" class="icon-success">
                                    <p>{{ getMyLanguage("client-space", "client-space.account-success.msg") }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ion-content>


            <!-- MODAL CHANGE PASSWORD -->
            <div class="modal modal-change-password" v-if="changePass.modal">
                <div class="modal-cont">
                    <div class="close-modal" @click="changePass.modal = false"></div>
                    <div class="box" v-if="changePass.confirmChange">
                        <div class="change-pass-content">
                            <p><strong>{{getMyLanguage("client-space","client-space.account.modal.really-change-password")}}</strong></p>
                        </div>
                        <div class="buttons">
                            <button @click="changePassword()" v-if="!changePass.load" class="btn-primary">{{getMyLanguage("client-space","client-space.account.modal.btn-yes")}}</button>
                            <button @click="changePass.modal = false" class="btn-cancel-primary">{{getMyLanguage("client-space","client-space.account.modal.btn-no")}}</button>
                        </div>
                    </div>

                    <div class="box" v-if="emailRemember.load">
                        <div class="load load-110"></div>
                    </div>

                    <!-- CHANGE SUCCESS -->
                    <div class="box" v-if="changePass.successChange && !emailRemember.load">
                        <div class="change-pass-content change-success">
                            <p><strong>{{getMyLanguage("client-space","client-space.account.modal.sent-email-instructions-change-password")}}</strong></p>
                            <div class="attention"><p v-html='getMyLanguage("client-space","confirm-email-spam")'></p></div>
                        </div>
                    </div>

                    <!-- CHANGE ERROR -->
                    <div class="box" v-if="changePass.errorChange && !emailRemember.load">
                        <div class="change-pass-content change-error">
                            <div class="box" v-html='getMyLanguage("client-space","client-space.account.modal.changePass-error")'></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overlayModal" v-if="changePass.modal"></div>


            <ul class="list-ddi" v-if="selectedCountryDDI.openSelect === true">
                <li v-for="(ddiList,index) in dataCountry" :key="index" @click="(dataProfile.personPhoneCountryId = ddiList.id), (selectedCountryDDI.phoneMask = ddiList.phoneMask), (this.changeMask()), (selectedCountryDDI.ddi = ddiList.ddi), (selectedCountryDDI.flag = ddiList.flag), (selectedCountryDDI.openSelect = false)">
                    <div class="flag">
                    <img class="anime" :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + ddiList.flag + "/flags"'>
                    </div>
                    <span class="name-country">{{ddiList.name}}</span>
                    <span class="ddi">(+{{ddiList.ddi}})</span>
                </li>
            </ul>

            <div class="overlay-list-ddi" v-if="selectedCountryDDI.openSelect === true">
                <div class="close-select-ddi" @click="selectedCountryDDI.openSelect = false"></div>
            </div>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import moment from 'moment';

    import api from '@/config/apiPlatform.js';

    import {mask} from 'vue-the-mask'

    import Header from '@/components/dneplatform/Header.vue';
    // import Step from '@/components/dneplatform/Step.vue';
    import Footer from '@/components/dneplatform/Footer.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            Header,
            // Step,
            Footer
        },
        directives: {mask},
        data () {
            return {
                showContent: false,
                flagSelected: {},
                dataProfile: "",
                dataCountry: [],
                dataLanguage: [],
                dataAsset: [],
                dataGender: [],
                dataProfileShow: false,
                indication: {
                    overlay: false
                },
                selectedCountryDDI: {
                    changeMask: true,
                    phoneMask: '',
                    countrySelected: false,
                    flag: ''
                },

                emailRemember: {
                    load: false
                },

                changePass: {
                    modal: false,
                    email: '',
                    confirmChange: true,
                    successChange: false
                }
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
            dateTime(value) {
                // return moment(value).locale(this.flagSelected).format('DD/MM/YYYY');
                return moment(value).format('DD/MM/YYYY');
            },

            changePassModal(){
                this.changePass.email = this.dataProfile.personEmail
                this.changePass.modal = true
                this.changePass.confirmChange = true
                this.changePass.successChange = false
            },

            changePassword(){
                this.changePass.confirmChange = false
                this.emailRemember.load = true
                
                api.post("/api/v1/platform/account/password/reset", this.emailRemember)
                .then(response => {
                    this.registerId = response.data.id;
                    
                    setTimeout(() => {
                        this.emailRemember.load = false
                        this.changePass.successChange = true
                    }, 500);
                })
                .catch(error => {
                    this.emailRemember.load = false
                    this.changePass.errorChange = true

                    console.error("There was an error!", JSON.stringify(error));
                })
            },

            changeMask(){
                this.selectedCountryDDI.changeMask = false

                setTimeout(() => {
                    const maskPhone = this.selectedCountryDDI.phoneMask
                    
                    this.selectedCountryDDI.phoneMask = maskPhone.replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#")

                    this.selectedCountryDDI.changeMask = true
                }, 1000);
            },

            saveProfile(){
                window.location.href = '/home'
            },

            copyUrl(){
                // pendente gerar o link e copiar

                this.openToast()
            },

            async openToast() {
                const toast = await toastController
                    .create({
                    message: this.getMyLanguage("profile", "profile.copied.link"),
                    color: "success",
                    duration: 2000
                    })
                return toast.present();
            },
        },

        async mounted () {
            this.flagUrl()

            this.dataProfile = JSON.parse(localStorage.getItem("profile"))
            
            const resumeClientCountry = await api.get('/api/v1/platform/country')
            this.dataCountry = resumeClientCountry.data
            this.dataCountryAccount = resumeClientCountry.data.filter(item => item.id === this.dataProfile.personPhoneCountryId)[0]
            // console.log(this.dataCountry)
            // console.log(this.dataCountryAccount)

            const resumeClientLanguage = await api.get('/api/v1/platform/language')
            this.dataLanguage = resumeClientLanguage.data
            
            const resumeClientAsset = await api.get('/api/v1/pay/asset/type/fiat')
            this.dataAsset = resumeClientAsset.data

            const resumeClientGender = await api.get('/api/v1/public/attributes/codes/person_gender')
            this.dataGender = resumeClientGender.data
            
            setTimeout(() => {
                this.selectedCountryDDI.phoneMask = this.dataCountryAccount.phoneMask
                this.selectedCountryDDI.flag = this.dataCountryAccount.flag
                this.selectedCountryDDI.ddi = this.dataCountryAccount.ddi

                this.showContent = true
            }, 100);
            
            this.dataProfileShow = true
        }
        
        // created () {
            
        // }
    }
</script>


<style scoped>
    .cont-updated-account .box{margin: 0 auto 40px; max-width: 400px; overflow-x: auto; background: var(--color-text-tertiary); border-radius: 12px; padding: 16px; position: relative; z-index: 10; -webkit-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -moz-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);}
    .cont-updated-account .box .icon-success{display: block; margin: 0 auto 20px; width: 120px;}
    .cont-updated-account .box p{text-align: center; font-family: var(--font-primary-semibold); color: var(--color-secondary);}
</style>