<template>
    <ion-app :scroll-events="true">
        <div class="sign-register">
            <video id="dneVideo" class="bg-video" autoplay="autoplay" loop="true" muted defaultmuted playsinline>
                <source src="@/assets/images/content/dne-token.mp4" type="video/mp4">
                <source src="@/assets/images/content/dne-token.webm" type="video/webm">
            </video>

            <div class="logo">
                <img src="@/assets/images/logo-dne.svg" >
            </div>
            
            <ion-page>
                <ion-content v-if="stepLogin.statusEmailPass == true">
                    <div class="content-login">
                        <!-- <div class="bts-login-register">
                            <div class="lnk-tab active">{{ getMyLanguage("login", "login.nav.signIn") }}</div>
                            <div class="lnk-tab" @click="createAccount()">{{ getMyLanguage("login", "login.nav.createAccount") }}</div>
                        </div> -->

                        <div class="form-login ion-padding" v-if="!loginRemember.show">
                            <ion-row>
                                <ion-col class="header">
                                    <h3>{{ getMyLanguage("login", "login.hello.title") }}</h3>
                                    <p>{{ getMyLanguage("login", "login.hello.msg") }}</p>
                                </ion-col>
                                <ion-col size="auto" class="selectLang">
                                    <SelectLanguage />
                                </ion-col>
                            </ion-row>

                            <form>
                                <ion-list>
                                    <!-- EMAIL LOGIN -->
                                    <ion-item lines="none">
                                        <input v-model="sign.username" type="email" @keyup.enter="validateForm()" class="form form-email" :class='{error: sign.emptyUsername}' spellcheck="false" autocapitalize="off" :placeholder='getMyLanguage("login", "login.placeholder.email")'>
                                    </ion-item>

                                    <!-- PASSWORD -->
                                    <ion-item lines="none">
                                        <div class="enterPass">
                                            <input v-model="sign.password" type="password" v-if="!showPass" @keyup.enter="validateForm()" class="form form-pass" :class='{error: sign.emptyPassword}' :placeholder='getMyLanguage("login", "login.placeholder.password")'>
                                            <input v-model="sign.password" type="text" v-if="showPass" @keyup.enter="validateForm()" class="form form-pass" :class='{error: sign.emptyPassword}' :placeholder='getMyLanguage("login", "login.placeholder.password")'>

                                            <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                                            <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                                        </div>
                                    </ion-item>

                                    <div class="remember-password">
                                        <div class="btn-remember-password" @click="loginRemember.show = true">{{ getMyLanguage("login", "login.remember-password") }}</div>
                                    </div>

                                    <ion-item>
                                        <ion-button class="btnPrimary btn-disable" v-if="sign.username.length <= 5 || sign.password.length <= 7">{{ getMyLanguage("login","login.btn.continue") }}</ion-button>
                                        <ion-button @click="validateForm()" expand="block" class="btnPrimary" v-if="stepLogin.load === false && sign.username.length > 5 && sign.password.length > 7">{{ getMyLanguage("login", "login.btn.continue") }}</ion-button>
                                        <div class="btn-primary btn-loading" v-if="stepLogin.load === true"></div>
                                    </ion-item>

                                </ion-list>
                            </form>
                        </div>


                        <!-- REMEMBER PASS -->
                        <div class="form-login remember ion-padding" v-if="loginRemember.show">
                            <ion-row>
                                <ion-col class="header">
                                    <h3>{{ getMyLanguage("login", "login.remember-password") }}</h3>
                                    <p>{{ getMyLanguage("login", "login.remember-password.msg") }}</p>
                                </ion-col>
                                <ion-col size="auto" class="selectLang">
                                    <SelectLanguage />
                                </ion-col>
                            </ion-row>

                            <form>
                                <!-- EMAIL LOGIN -->
                                <ion-list v-if="!loginRemember.success && !loginRemember.error">
                                    <ion-item lines="none">
                                        <input v-model="emailRemember.email" type="email" class="form form-email" :class='{error: sign.emptyUsernameRemember}' spellcheck="false" autocapitalize="off" :placeholder='getMyLanguage("login", "login.remember-email-registered")'>
                                    </ion-item>

                                    <ion-item>
                                        <ion-button class="btnPrimary btn-disable" v-if="emailRemember.email.length <= 5">{{ getMyLanguage("login","login.btn.rememberPass") }}</ion-button>
                                        <ion-button @click="rememberPass()" expand="block" class="btnPrimary" v-if="stepLogin.load === false && emailRemember.email.length > 5">{{ getMyLanguage("login", "login.btn.rememberPass") }}</ion-button>
                                        <div class="btn-primary btn-loading" v-if="stepLogin.load === true"></div>
                                    </ion-item>
                                </ion-list>

                                <!-- SUCCESS REMEMBER -->
                                <ion-list v-if="loginRemember.success">
                                    <ion-item>
                                        <div class="box box-success">
                                            <p>{{ getMyLanguage("login","login.remember-password.success") }}</p>
                                        </div>
                                    </ion-item>
                                </ion-list>
                               
                                <!-- ERROR REMEMBER -->
                                <ion-list v-if="loginRemember.error">
                                    <ion-item>
                                        <div class="box box-error">
                                            <p>{{ getMyLanguage("login","login.remember-password.error") }}</p>
                                        </div>
                                    </ion-item>
                                </ion-list>
                            </form>
                        </div>

                    </div>
                </ion-content>


                <!-- CONFIRM CODE -->

            </ion-page>
        </div>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, IonRow, IonItem, IonCol, IonList, IonButton, toastController} from '@ionic/vue';

    import SelectLanguage from '@/components/SelectLanguage.vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import api from '@/config/apiPlatform.js'

    export default {
        components: {
            IonApp,IonPage, IonContent, IonRow, IonItem, IonCol, IonList, IonButton,
            SelectLanguage
        },
        data () {
            return {
                flagSelected: {},
                showPass: false,
                stepLogin: {
                    'load': false,
                    'loadCode': false,
                    'statusEmailPass': true,
                    'statusCode': false
                },
                sign: {
                    "username": "",
                    "password": ""
                },
                codeConfirm: {},

                loginRemember: {
                    show: false,
                    success: false,
                    error: false
                },
                emailRemember: {
                    email: ""
                }
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            createAccount(){
                // this.urlCreateAccount = '/' + this.flagSelected + '/register'
                window.location.href = this.urlCreateAccount
            },

            referrerId(){
                const idRef = window.location.href.split("referrer=");
                console.log(idRef)

                if ( idRef[1] != undefined ){
                    this.urlCreateAccount = '/' + this.flagSelected + '/register/?referrer=' + idRef[1]
                } else{
                    this.urlCreateAccount = '/' + this.flagSelected + '/register'
                }
            },

            resendCode(){
                // window.alert('Resend Code')
                window.navigator.vibrate(200);
            },

            validateForm(){
                this.stepLogin.load = true

                if ( this.sign.username == undefined || this.sign.username == "" ){
                    this.sign.emptyUsername = true
                    this.stepLogin.load = false
                } else if ( this.sign.password == undefined || this.sign.password == "" ){
                    this.sign.emptyUsername = false
                    this.sign.emptyPassword = true
                    this.stepLogin.load = false
                } else{
                    this.sign.emptyUsername = false
                    this.sign.emptyPassword = false
                    
                    // this.stepLogin.load = true
                    this.loginPlatform()

                    // setTimeout(() => {
                    //     // this.stepLogin.load = false
                    //     // this.stepLogin.statusEmailPass = false
                    //     // this.stepLogin.statusCode = true

                    // }, 1500);
                }
            },

            validateCode(){
                
                if ( this.codeConfirm._2fa == undefined || this.codeConfirm._2fa == "" ){
                    this.codeConfirm.empty2fa = true
                } else{
                    this.stepLogin.loadCode = true

                    setTimeout(() => {
                        // window.location.href =  this.flagSelected + "/home/ffc3979a-7cb0-4de1-8e23-0536d96db0cc"
                        // this.loginPlatform()
                    }, 1500);
                }
            },


            rememberPass(){
                this.stepLogin.load = true
                
                api.post("/api/v1/platform/account/password/reset", this.emailRemember)
                .then(response => {
                    console.log(response)
                    this.loginRemember.success = true
                    this.stepLogin.load = false

                })
                .catch(error => {
                    console.log(error)
                    this.loginRemember.error = true
                    this.stepLogin.load = false
                })
            },

            loginPlatform(){
                const loginPlatform = this.sign;

                api.post("/api/v1/platform/account/login", loginPlatform)
                .then(response => {
                    this.stepLogin.load = true

                    localStorage.setItem('accessToken',response.data.accessToken);
                    localStorage.setItem('codeLanguage',response.data.profile.account_language_alpha_2);
                    localStorage.setItem('flagLanguage',response.data.profile.account_language_flag);
                    

                    this.flagSelected = localStorage.getItem('codeLanguage')
                    
                    window.location.href =  this.flagSelected + '/platform/home/'
                })
                .catch(error => {
                    this.stepLogin.load = false
                    this.errorMessage = error.message;
                    this.openToast();
                    console.clear()
                    console.log(error.message);
                })
            },


            loginCode(){
                const loginCode = this.codeConfirm;

                api.post("/api/v1/platform/account/login", loginCode)
                .then(response => {
                    this.registerId = response.data.id;
                    window.location.href =  this.flagSelected + '/home/' + response.data.id

                    // const languageRedirect = String(window.location.pathname).split("/")[1];
                    // window.location.href = '/' + languageRedirect + '/activate/' + response.data.id
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    // console.error("There was an error!", error);
                    console.log(error.response.data)

                    // this.$toast.error(error.response.data);
                    // this.$toast.show(`Hey! I'm here`);
                    // this.$toast.success(`Hey! I'm here`);
                    // this.$toast.warning(`Hey! I'm here`);
                    // this.$toast.info(`Hey! I'm here`);

                    // setTimeout(this.$toast.clear, 5000)
                })
            },

            async openToast() {
                const toast = await toastController
                    .create({
                    message: this.getMyLanguage("login","login.error.login-password"),
                    color: "danger",
                    duration: 2000
                    })
                return toast.present();
            },
        },


        async mounted () {
            this.flagUrl();

            setTimeout(() => {
                this.referrerId()
            }, 1000);
        }
        
        // created () {
            
        // }
    }
</script>


<style scoped>
    .selectLang{position: initial;}

    ion-content{--background: var(--color-none); height: 100%;}
    .two-bts{display: flex; justify-content: space-between; align-items: center;}
    /* .btn-primary{max-width: 160px;} */

    /* .sign-register{background: url('~@/assets/images/banner-login-register.jpg') no-repeat top center; background-size: 100% auto; height: 100%;} */
    .sign-register{background: var(--color-primary); height: 100%;}
    .header-md::after{display: none;}

    .bg-video{display: block; min-width: 100%; height: calc(100% - 200px);}
    
    .logo{background: url('~@/assets/images/bg-logo.png') no-repeat; background-size: 100% auto; width: 140px; padding: 12px 26px 12px 16px; position: absolute; z-index: 20; left: 0; top: 40px;}
    .logo img{display: block;}

    .content-login{position: absolute; left: 50%; bottom: 0; transform: translateX(-50%); width: 100%; max-width: 600px;}
    /* .content-login ion-col{padding: 0; padding-inline-start: 0; padding-inline-end: 0; height: 40px; display: block;} */
    .bts-login-register{display: flex; justify-content: center;}
    .lnk-tab{display: block; width: 100%; font-size: 14px; line-height: 40px; text-align: center; color: #ffffff; text-decoration: none; font-weight: 600;}
    .lnk-tab.active{background: #DDDDDD; color: var(--color-primary); border-top-right-radius: 20px;}

    .box-error, .box-success{margin: 0 auto;}
    .box-error p{margin: 0;}

    @media (min-width: 576px){
        .bg-video{height: 100%;}
        .lnk-tab.active{border-radius: 20px 20px 0 0;}
    }

    @media (min-width: 992px){
        .bg-video{min-width: 100%; min-height: 100%; height: auto;}
    }
    
    ion-list{margin: 15px 0;}
    ion-col.v-center{display: flex; align-items: center;}
    ion-item{--inner-border-width: 0}

    .toast-danger{--background: var(--color-cancel);}

    .content-confirm-code{position: absolute; left: 0; bottom: 0; width: 100%;}

</style>