<template>
    <div class="row phases">    
        
        <!-- PHASE PUBLIC -->
        <div class="col-12 col-lg-9 phases-publics">
            <h2>{{getMyLanguage("purchase-token", "phase-private.nameGroupPhasePublic")}}</h2>
            <div class="row">

                <div class="col-12 col-lg-4" v-for="(itemPhase, index) in dataPhasePublic" :key="index">
                    <div class="phase" :class="itemPhase.statusExtra">
                        <div class="phase-cont">
                            <h3>
                                <h3>{{getMyLanguage("purchase-token", "phase-public.name." + itemPhase.name)}}</h3>
                                <small>{{ dateTimePhase(itemPhase.startDate) }} / {{ dateTimePhase(itemPhase.endDate) }}</small>
                                <i class="bt-action more" @click="showContPhasePublic = itemPhase.id" v-if="showContPhasePublic != itemPhase.id"></i>
                                <i class="bt-action less" @click="showContPhasePublic = null" v-if="showContPhasePublic === itemPhase.id"></i>
                            </h3>
                            <p class="price-for"><span>{{ this.getMyLanguage("purchase-token", "purchase-token.content.phase.package") }} {{itemPhase.quantity}} tokens:</span> <strong>{{itemPhase.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</strong></p>
                            
                            <div class="phase-cont-complete" v-if='showContPhasePublic === itemPhase.id'>
                                <p><span>{{ getMyLanguage("purchase-token", "purchase-token.content.phase.discount") }}:</span> <strong>{{itemPhase.discount}}%</strong></p>
                                <p><span>{{ getMyLanguage("purchase-token", "purchase-token.content.phase.bonus") }}:</span> <strong>{{itemPhase.bonus}}%</strong></p>
                                <p><span>{{ getMyLanguage("purchase-token", "purchase-token.content.phase.minAmount") }}:</span> <strong>{{itemPhase.minAmount}} {{ getMyLanguage("purchase-token", "purchase-token.content.phase.packages") }}</strong></p>
                                <p><span>{{ getMyLanguage("purchase-token", "purchase-token.content.phase.maxAmount") }}:</span> <strong>{{itemPhase.maxAmount}} {{ getMyLanguage("purchase-token", "purchase-token.content.phase.packages") }}</strong></p>
                                <p><span>{{ getMyLanguage("purchase-token", "purchase-token.content.phase.dateStart") }}:</span> <strong>{{ dateTime(itemPhase.startDate) }}</strong></p>
                                <p><span>{{ getMyLanguage("purchase-token", "purchase-token.content.phase.dateEnd") }}:</span> <strong>{{ dateTime(itemPhase.endDate) }}</strong></p>
                                <p><span>{{ getMyLanguage("purchase-token", "purchase-token.content.phase.timeStake") }}:</span> <strong>{{itemPhase.stakeMo}} {{ getMyLanguage("purchase-token", "purchase-token.content.phase.months") }}</strong></p>
                                <p><span>{{ getMyLanguage("purchase-token", "purchase-token.content.phase.maxSelling") }}:</span> <strong>{{itemPhase.maxQuantity.toLocaleString()}} {{ getMyLanguage("purchase-token", "purchase-token.content.phase.packages") }}</strong></p>

                                <div @click="lnkToken()" class="btn-secondary">{{ getMyLanguage("purchase-token", "purchase-token.content.phase.purchaseNow") }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import api from '@/config/apiPlatform.js';
    import moment from 'moment';

    export default {
        components: {
            
        },
        data () {
            return {
                flagSelected: {},
                // dataPhasePrivate: {},
                dataPhasePublic: [],
                dataPhaseCurrent: [],
                showContPhasePrivate: true,
                showContPhasePublic: {}
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            dateTime(value) {
                return moment(value).locale(this.flagSelected).format('ll');
            },

            dateTimePhase(value) {
                return moment(value).locale(this.flagSelected).format('DD MMM');
            },
            
            lnkToken(){
                window.location.href = this.flagSelected + '/token/'
            },
        },

        async mounted () {
            this.flagUrl();

            const resumePhase = await api.get('api/v1/ico/phase')
            // this.dataPhasePrivate = resumePhase.data.filter(priv => priv.phaseType === "Private")[0]
            this.dataPhasePublic = resumePhase.data.filter(pub => pub.phaseType === "Public")

            const resumePhaseCurrent = await api.get('api/v1/ico/phase/current')
            this.dataPhaseCurrent = resumePhaseCurrent.data
        }
    }
</script>


<style scoped>
    .phases{-webkit-animation: showTopScale .4s alternate;}
    .phases h2{font-family: var(--font-secondary-bold); color: var(--color-text-tertiary); font-size: 20px; height: 50px; line-height: 50px; text-transform: uppercase; background: var(--color-secondary); text-align: center; margin: 0; border-radius: 12px 12px 0 0; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}

    .phases .phase{overflow: hidden;; margin-bottom: 10px;}
    .phases .phase .phase-cont{padding: 20px; margin-bottom: 20px; background: #fff; border-radius: 0 0 12px 12px;}
    .phases .phase .phase-cont h3{font-family: var(--font-secondary-bold); color: var(--color-text-secondary); font-size: 24px; line-height: 30px; margin: 0 0 5px 0; position: relative; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
    .phases .phase .phase-cont h3 small{display: block; font-size: 13px;}
    .phases .phase .phase-cont h3 i.bt-action{display: block; position: absolute; z-index: 20; left: 0; top: 0; width: 100%;  height: 30px; transition: .3s;}
    .phases .phase .phase-cont h3 i.bt-action:after{content: ''; display: block; position: absolute; right: 0; top: 11px; width: 0;  height: 0; transition: .3s;}
    .phases .phase .phase-cont h3 i.bt-action.more:after{border-left: 7px solid transparent; border-right: 7px solid transparent; border-top: 7px solid var(--color-text-secondary);}
    .phases .phase .phase-cont h3 i.bt-action.less:after{border-left: 7px solid transparent; border-right: 7px solid transparent; border-bottom: 7px solid var(--color-text-secondary);}
    .phases .phase .phase-cont h3.openPhase:after{transform: rotate(180deg);}

    .phases .phase .phase-cont .price-for{margin-bottom: 10px;}
    .phases .phase .phase-cont .price-for del{color: var(--color-quaternary);}
    .phases .phase .phase-cont .description{margin-top: 20px;}
    .phases .phase .phase-cont .phase-cont-complete{-webkit-animation: showOpacity .4s alternate;}
    .phases .phase .phase-cont p{display: flex; justify-content: space-between; font-size: 14px; line-height: 24px; margin-bottom: 0;}
    .phases .phase .phase-cont .btn-primary{margin-top: 30px;}
    .phases .phase .phase-cont .btn-secondary{margin-top: 15px; display: none;}

    .phases .phase.phase_past h3, .phases .phase.phase_past p{opacity: .4; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}

    .phases .phase.phase_current h3{color: var(--color-secondary);}
    .phases .phase.phase_current h3:after{display: none;}
    .phases .phase.phase_current .btn-primary{display: inline-flex;}
    .phases .phase.phase_current .btn-secondary{display: inline-flex;}
    .phases .phase.phase_current .phase-cont{}
    .phases .phase.phase_current .phase-cont .phase-cont-complete{}
    .phases .phase.phase_current h3 i{display: none !important;}

    .phase-private.phase_current h2{background: var(--color-secondary);}
    .phase-private.phase_current .phase{background: var(--color-text-tertiary);}
    .phase-private .phase{border-radius: 0 0 12px 12px;}

    .phases-publics.phase_past h2{background: var(--color-secondary);}
    .phases-publics .row{margin: 0; background: var(--color-text-tertiary); border-radius: 0 0 12px 12px;}
    .phases-publics .row .col-12 .phase{position: relative; height: 100%;}
    .phases-publics .row .col-12 .phase:after{content: ''; display: block; width: 90%; height: 2px; background: var(--color-tertiary); position: absolute; right: 5%; bottom: 1px;}
    .phases-publics .row .col-12:last-child .phase:after{display: none;}


    @media (min-width: 576px){
        .phases .phase .phase-cont .phase-cont-complete p{border-bottom: 1px solid #eee; line-height: 30px;}
    }
</style>