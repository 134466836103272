<template>
    <ion-app :scroll-events="true" class="token">
        <ion-page>
            <div class="header-step">
                <Header />
            </div>

            <ion-content class="itoken-content">
                <div class="content">
                    <!-- <Step /> -->

                    <div class="ion-padding">
                        <h1 class="title-primary">DNE <span>T</span>oken</h1>

                        <!-- <div class="success-purchase" v-for="(itemHistory,index) in dataHistory" :key="index"> -->
                        <div class="load-green" v-if="lengthBuy.showContent == false"></div>
                        
                        <div class="success-purchase" v-if="lengthBuy.showContent == true">
                            <h4>{{ getMyLanguage("tokenPaymentSuccess", "tokenPaymentSuccess.title") }}</h4>
                            <h6><strong><span v-text="dataHistory.quantity * 180"></span> DNE Token > {{dataHistory.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</strong></h6>
                            <!-- <p><strong>180 DNE Token > 81 €</strong></p> -->

                            <div class="box summary">
                                <p>{{ getMyLanguage("summary", "summary.originalPrice") }}: <strong>180 DNE Token > 90 €</strong></p>
                                <p>{{ getMyLanguage("summary", "summary.phase") }}: <strong>{{dataHistory.phaseName}}</strong></p>
                                <p>DNE Tokens: <strong v-text="dataHistory.quantity * 180"></strong></p>
                                <p>{{ getMyLanguage("summary", "summary.price") }}: <strong>{{dataHistory.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</strong></p>
                                <p>{{ getMyLanguage("summary", "summary.stake") }}: <strong>{{dataHistory.stakeTerm}} {{ getMyLanguage("summary", "summary.stake.months") }}</strong></p>
                                <!-- <p>{{ getMyLanguage("summary", "summary.total") }}: <strong>216 DNE Token</strong></p> -->
                            </div>
                        </div>

                        <div class="ion-padding" v-if="lengthBuy.showContent == true">
                            <button @click="successToken()" expand="block" class="btn-primary">{{ getMyLanguage("tokenPaymentSuccess", "tokenPaymentSuccess.btn") }}</button>
                        </div>

                        <!-- <ion-row class="ion-padding">
                            <ion-col>
                                <ion-button @click="successToken()" type="submit" expand="block" class="btn-primary">Go Wallets</ion-button>
                            </ion-col>
                        </ion-row> -->
                    </div>
                </div>
            </ion-content>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";
    
    import apiInternational from '@/config/apiInternational.js';

    import Header from '@/components/dneinternational/Header.vue';
    // import Step from '@/components/dneplatform/Step.vue';
    import Footer from '@/components/dneinternational/Footer.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            Header,
            // Step,
            Footer
        },
        data () {
            return {
                flagSelected: {},
                dataHistory: [],
                lengthBuy: {
                    'showContent': false
                }
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
            
            successToken(){
                window.location.href = this.flagSelected +  '/international/activity/'
            },

            // lastBuy(){
            //     const lastBuy = this.dataHistory.length -1;
            //     this.lengthBuy.qtd = lastBuy;
            // }
        },

        async mounted () {
            this.flagUrl();

            const resumeHistory = await apiInternational.get('/api/v1/platform/account/purchases/')
            this.dataHistory = resumeHistory.data

            const lastBuy = this.dataHistory.length -1;
            this.lengthBuy.qtdList = lastBuy

            this.dataHistory = this.dataHistory[this.lengthBuy.qtdList]
            this.lengthBuy.showContent = true

            // this.lastBuy();
        },
        
        // created () {
        //     this.lastBuy();
        // }
    }
</script>


<style scoped>
    .success-purchase{padding: 16px; border-radius: 12px; margin-bottom: 10px; background: url('~@/assets/images/bg-green.png') no-repeat; background-size: cover; -webkit-animation: showScale .4s alternate;}
    .success-purchase h4{font-size: 20px; color: var(--color-text-tertiary); margin-bottom: 5px; text-align: center;}
    .success-purchase h6{color: var(--color-primary); text-align: center; font-size: 14px;}

    .success-purchase .summary{margin: 30px 0 10px 0;}
    .success-purchase .summary p{margin-bottom: 0;}
    .success-purchase .summary p strong{color: var(--color-primary);}

    .btn-primary{background: var(--color-primary); -webkit-animation: showTopScale .4s alternate;}
</style>