<template>
    <div>
        <div class="select-language">
            <div class="close-flags" v-if="showFlags" @click="showFlags = false"></div>
            <div class="selected-lang">
                <div class="flag">
                    <span v-for="(langSelected,index) in dataLanguageSelected" :key="index" :title="langSelected.name" @click="showSelectLanguage()">
                        <img v-if="flagShow" :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + langSelected.flag + "/flags"'>
                    </span>
                </div>
            </div>
            <div class="box flags-language" v-if="showFlags">
                <div class="list-language">
                    <div class="flag" v-for="(langs,index) in dataLanguage" :key="index" @click="changeLanguage(langs.code)">
                        <img :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + langs.flag + "/flags"'>
                        <span>{{langs.name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import getMyFavouriteLanguage from "@/services/language.js";
    
    import api from '@/config/apiPlatform.js'

    export default{
        data: function () {
            return {
                language: '',
                showFlags: false,
                group: 'dne-platform',
                flagSelected: {},
                dataLanguage: [],
                dataLanguageSelected: [],
                flagShow: false
            }
        },
        async mounted(){
            this.flagUrl();

            const resumeLanguage = await api.get('api/v1/platform/language')
            this.dataLanguage = resumeLanguage.data
            this.dataLanguageSelected = resumeLanguage.data.filter(lang => lang.code === this.flagSelected)

            this.flagShow = true
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
                localStorage.setItem('lang',flagSelect);
            },

            showSelectLanguage(){
                this.showFlags = true
            },

            changeLanguage(parLanguage){
                this.language = parLanguage;
                const urlLang = String(window.location.pathname).split("/");
                
                urlLang[1] = parLanguage;
                window.location.href = `${urlLang.join('/')}`;
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        }
    }
</script>


<style scoped>
    .select-language{position: relative; z-index: 30;}
    .select-language .flag{height: 30px; position: relative; display: flex; align-items: center; cursor: pointer; font-size: 12px; font-weight: 500; color: var(--color-primary);}
    .select-language .flag img{display: block; width: 24px; margin-right: 5px;}
    
    .selected-lang .flag:after{content: ''; display: block; width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid #888;}
    .selected-lang .flag img{}
    .selected-lang .flag img.active{display: block;}
    .selected-lang .flag span{display: flex; align-items: center; width: 30px; height: 30px;}

    .close-flags{position: absolute; z-index: 20; top: 0; left: 0; width: 100%; height: 100%;}

    .flags-language{position: absolute; top: 35px; right: 0; width: 140px; padding: 16px 6px 16px 16px;}
    .flags-language .list-language{overflow-y: auto; max-height: 150px; padding-right: 10px;}
    .flags-language .list-language::-webkit-scrollbar{width: 4px; height: 100%;}
    .flags-language .list-language::-webkit-scrollbar-thumb{background: var(--color-secondary); border-radius: 5px;}
    .flags-language .list-language::-webkit-scrollbar-track{background: var(--color-tertiary); border-radius: 5px;}
    .flags-language .list-language{scrollbar-face-color: var(--color-secondary); scrollbar-track-color: var(--color-tertiary);}

    .flags-language .list-language .flag{white-space: nowrap; padding: 0; background-size: 24px auto !important; transition: .3s; border-bottom: 1px solid #eee;}
    .flags-language .list-language .flag:last-child{border-bottom: none;}

    .flags-language .list-language .flag span{-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}


    .welcome .avatar .select-language{position: absolute; top: 10px; left: 40px;}
    .welcome .avatar .select-language .flag img{display: block; width: 24px; margin-right: 0;}
    
    .welcome .avatar .selected-lang .flag{position: absolute; z-index: 20; width: 30px;}
    .welcome .avatar .selected-lang .flag:after{position: absolute; right: 0; top: 50%; transform: translateY(-50%);}
    .welcome .avatar .selected-lang .flag span{background-size: 18px auto !important; background-position: center left;}
</style>