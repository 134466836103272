<template>
    <ion-app :scroll-events="true" class="construction">
        <ion-page>
            <div class="header-step">
                <Header />
            </div>

            <ion-content class="ion-padding construction-content">
                <div class="content">
                    <div class="load-white" v-if="!showContent"></div>
                    
                    <div class="construction-cont" v-if="showContent">
                        <h1 class="title-primary">DNE <span>M</span>arket</h1>
                        <p>{{getMyLanguage("construction","construction.title")}}</p>
                    </div>
                </div>
            </ion-content>
            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/dnemarket/Header.vue';
    import Footer from '@/components/dneplatform/Footer.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                flagSelected: {},
                showContent: false,
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
        },


        async mounted () {
            this.flagUrl()

            setTimeout(() => {
                this.showContent = true
            }, 2000);
        }
        
        // created () {
            
        // }
    }
</script>


<style scoped>
    /* .ion-page{background: #000;}
    .construction-content{--background: transparent;} */
    .content{height: 100%; position: relative; background-size: cover !important; border-radius: 12px;}
    @media (max-width: 576px){
        .content{background: url('~@/assets/images/content/market-smart.jpg') no-repeat;}
    }
    @media (min-width: 576px){
        .content{background: url('~@/assets/images/content/market-tablet.jpg') no-repeat;}
    }
    .content:before{content: ''; display: block; width: 100%; height: 100%; position: absolute; z-index: 20; left: 0; top: 0; background: rgba(31,53,97,0.5); border-radius: 12px;}

    .construction-cont{position: absolute; z-index: 30; top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%); width: calc(100% - 32px); text-align: center;}
    .construction-cont .title-primary{color: var(--color-text-tertiary);}
    .construction-cont p{color: var(--color-text-tertiary);}
    .load-white{position: absolute; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); width: 120px; height: 120px; margin: 0;}
</style>