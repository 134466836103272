<template>
    <div>
        <div class="box status" v-if="showContent && !dataProfile.profile_phone_number_verified">
            <div class="phone">
                <div class="status-phone pending"></div>
                +{{dataProfile.profile_phone_country_ddi}} {{dataProfile.profile_phone_number}}
            </div>
            <span class="btn-validate" @click="modalPhoneValidate = true" v-if="!dataProfile.profile_phone_number_verified">{{ getMyLanguage("client-space","client-space.account.lbl.phone.validate.btn") }}</span>
        </div>


        <!-- MODAL -->
        <div class="modal" v-if="modalPhoneValidate">
            <div class="box cont-modal">
                <div class="close-modal" @click="modalPhoneValidate = false"></div>
                
                <h2 class="title-secondary">{{getMyLanguage("client-space","client-space.account.lbl.phone.validate")}}</h2>
                <p class="step-validate" v-if="!flowSendSMS.success && !errors.flow" v-html='getMyLanguage("client-space","client-space.account.lbl.phone.validate-step01")'></p>
                
                <div class="load" v-if="flowSendSMS.load"></div>

                <!-- SMS CODE -->
                <div class="sms-code" v-if="!flowSendSMS.success && !errors.flow">                        
                    <div class="btn-primary getCode" v-if="!flowSendSMS.smsCodeShow && !flowSendSMS.load" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-generate")}}</div>

                    <div class="sms-code-cont">
                        <div class="invalid-code" v-if="errors.invalidCode">{{getMyLanguage("client-space","client-space.account.lbl.phone.validate-error")}}</div>

                        <div class="sms-confirm" :class="{errorCode: errors.invalidCode}" v-if="flowSendSMS.smsCodeShow && !flowSendSMS.load">
                            <input type="text" class="form form-smsCode" id="smsCodeForm" v-model="codeSMS.code" v-mask='"######"' placeholder="______" inputmode="numeric" autocomplete="one-time-code">
                            <div class="confirm btn-disabled" v-if="codeSMS.code.length < 6">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                            <div class="confirm" v-if="codeSMS.code.length == 6" @click="validateCodeSMS()">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                        </div>
                        
                        <div class="alert" v-if="flowSendSMS.smsCodeShow && !flowSendSMS.load">{{getMyLanguage("wallets","modal-send-code.msg-sms")}} +{{dataProfile.profile_country_ddi}} {{dataProfile.profile_phone_number}}</div>
                        <!-- <div class="load" v-if="flowSendSMS.smsCodeShow && flowSendSMS.load"></div> -->
                    </div>


                    <div class="resend" v-if="flowSendSMS.smsCodeShow && !flowSendSMS.load">
                        <div class="btn-resend resend-load" v-if="!flowSendSMS.btnResendSMS">{{getMyLanguage("wallets","modal-send-code.btn-resend-in")}} {{flowSendSMS.countDownResendCode}}s</div>
                        <div class="btn-resend" v-if="flowSendSMS.btnResendSMS" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-resend")}}</div>
                    </div>
                </div>


                <!-- BOX SUCCESS -->
                <div class="box-success" v-if="flowSendSMS.success">
                    <p v-html='getMyLanguage("client-space","client-space.account.lbl.phone.validate-ok")'></p>
                </div>


                <!-- BOX ERROR -->
                <div class="box-error" v-if="errors.flow != false">
                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                    <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errors.flow}}
                    </div>
                </div>
            </div>
        </div>

        <div class="overlayModal" v-if="modalPhoneValidate"></div>

    </div>
</template>


<script>
    import getMyFavouriteLanguage from "@/services/language.js";
    import api from '@/config/apiInternational.js'
    import {mask} from 'vue-the-mask'

    export default{
        directives: {mask},
        data: function () {
            return {
                showContent: false,
                flagSelected: {},
                dataProfile: "",
                
                modalPhoneValidate: false,

                codeSMS: {
                    code: ''
                },

                flowSendSMS: {
                    load: false,
                    smsCodeShow: false,
                    btnResendSMS: false,
                    success: false,
                    countDownResendCode: 0
                },

                errors: {
                    flow: false,
                    invalidCode: false
                }
            }
        },

        async mounted(){
            this.flagUrl();
            this.profile();
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            async profile(){
                this.dataProfile = JSON.parse(localStorage.getItem("profile"))

                setTimeout(() => {
                    this.showContent = true
                }, 2000);
            },

            async getCodeSend(){
                this.flowSendSMS.smsCodeShow = false
                this.flowSendSMS.load = true
                this.errors.invalidCode = false
                this.codeSMS.code = ''

                await api.get('/api/v1/platform/security/phone/validate')
                .then(response => {
                    console.log(response)
                    console.clear()

                    setTimeout(() => {
                        this.flowSendSMS.smsCodeShow = true
                        this.flowSendSMS.load = false
                        this.flowSendSMS.btnResendSMS = false

                        this.flowSendSMS.countDownResendCode = 15
                        this.countDownFlowSend()
                    }, 300);

                    // setTimeout(() => {
                    //     document.getElementById('smsCodeForm').focus()
                    // }, 400);
                })
                .catch(error => {
                    setTimeout(() => {
                        this.errors.flow = error.response.status
                    }, 500);

                    setTimeout(() => {
                        this.errors.flow = false
                        this.flowSendSMS.load = false
                    }, 5000);
                })
            },


            countDownFlowSend() {
                if(this.flowSendSMS.countDownResendCode > 0) {
                    setTimeout(() => {
                        this.flowSendSMS.countDownResendCode -= 1
                        this.countDownFlowSend()
                    }, 1000)
                } else{
                    this.flowSendSMS.btnResendSMS = true
                }
            },

            async validateCodeSMS(){
                this.flowSendSMS.load = true
                
                await api.post('/api/v1/platform/security/phone/validate', this.codeSMS)
                .then(response => {
                    console.log(response)
                    console.clear()

                    setTimeout(() => {
                        const resumeClient = api.get('/api/v1/platform/account/profile/')
                        this.dataProfile = resumeClient.data
                        const aux = JSON.stringify(this.dataProfile)
                        localStorage.setItem("profile",aux)


                        this.flowSendSMS.success = true
                        this.flowSendSMS.load = false
                        this.errors.invalidCode = false
                    }, 1000);
                })
                .catch(error => {
                    if ( error.response.status === 400 ){
                        if ( error.response.data === "phone_number_invalid_code" ){
                            this.errors.invalidCode = true
                            this.flowSendSMS.load = false
                        } else{
                            this.errors.flow = error.response.status
                        }
                    }
                    
                    this.flowSendSMS.load = false

                    // setTimeout(() => {
                    //     this.errors.flow = false
                    // }, 5000);
                })
            }
        }
    }
</script>


<style scoped>
    .status{display: flex; align-items: center; justify-content: center; margin: -12px 0 10px 0; padding: 28px 16px 16px 16px; background: #f2f2f2; -webkit-animation: alertPhone .6s alternate;}
    .status .phone{display: flex; align-items: center; font-size: 13px; color: var(--color-cancel);}
    .status .phone div{width: 30px; height: 30px; background-size: 18px !important; border-radius: 50%; position: relative; margin-right: 10px;}
    .status .phone div.status-phone{background: url('~@/assets/images/icons/client-space-resume-phone.svg') no-repeat center #fff;}
    .status .phone div:before{content: ''; display: block; width: 14px; height: 14px; border-radius: 50%; background-size: 60% auto !important; position: absolute; right: -4px; bottom: -4px;}
    .status .phone div.pending:before{background: url('~@/assets/images/icons/client-space-status-pending.svg') no-repeat center var(--color-cancel);}

    .btn-validate{background: url('~@/assets/images/icons/client-space-status-validate.svg') no-repeat 5px center #fff; background-size: 14px auto; font-size: 13px; padding: 4px 7px 4px 24px; margin-left: 20px; border: 1px solid var(--color-text-tertiary); border-radius: 6px; color: var(--color-text-secondary); cursor: pointer;}

    @keyframes alertPhone{
        0% {margin-top: -80px;}
        100% {margin-top: -12px;}
    }


    /* MODAL */
    .cont-modal{max-width: 400px; padding: 15px 12px;}
    .cont-modal h2{margin: 0 0 20px 0; font-size: 18px; line-height: 24px;}
    .cont-modal h3{margin: 20px 0 5px 0;}
    .cont-modal p{text-align: center;}
    .cont-modal .step-validate{background: #f5f5f5; text-align: center; border-radius: 12px; padding: 10px 10px; margin-bottom: 30px; font-size: 13px; line-height: 21px;}
    .cont-modal .box-error, .cont-modal .box-success{margin: 0;}
    .cont-modal .box{padding: 10px;}
    .cont-modal .sms-code{display: flex; flex-direction: column; justify-content: center; align-items: center;}
    .cont-modal .load{margin: 10px; height: 60px; background: url('~@/assets/images/icons/loading-secondary.svg') no-repeat center; background-size: auto 100%;}
    .cont-modal .sms-confirm{display: flex; align-items: center; width: 100%;}
    .cont-modal .sms-confirm.errorCode .form{border: 1px solid var(--color-cancel); border-right: none;}
    .cont-modal .sms-confirm.errorCode .confirm{border: 1px solid var(--color-cancel); border-left: none;}
    .cont-modal .form{margin-bottom: 0; width: calc(100% - 90px); border-radius: 12px 0 0 12px; border-right: 0; font-size: 18px; font-family: var(--font-primary-semibold); padding: 0 15px 0 20px; letter-spacing: 9px;}
    .cont-modal .form-smsCode{background: #eee; border: none;}
    .cont-modal .form-smsCode:focus{box-shadow: none;}
    .cont-modal .confirm{display: block; background: var(--color-secondary); width: 90px; height: 40px; line-height: 40px; font-size: 14px; color: var(--color-text-tertiary); text-align: center; border-radius: 0 12px 12px 0; cursor: pointer;}
    .cont-modal .invalid-code{width: 100%; font-size: 75%; color: var(--color-cancel);}
    .cont-modal .alert{font-size: 11px; line-height: 13px; width: 100%; margin-top: 8px;}
    .cont-modal .resend{display: flex; justify-content: center; width: 100%; padding-top: 15px; margin-top: 15px; border-top: 1px solid #eee;}
    .cont-modal .btn-resend{padding: 0 15px; border-radius: 6px; background: #ddd; font-size: 12px; line-height: 30px; cursor: pointer;}
    .cont-modal .btn-resend.resend-load{background: #eee;}
    .cont-modal .box-error h3{color: #fff;}

</style>