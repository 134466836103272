<template>
    <ion-app :scroll-events="true" class="token">
        <ion-page>
            <div class="header-step">
                <Header />
            </div>

            <ion-content class="token-cntent">
                <div class="ion-padding" v-if="showContent && blockPurchase">
                    <h2 class="title-primary">DNE <span>T</span>oken</h2>
                    <div class="box-error">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error","block-purchase") }}</p>
                    </div>
                </div>

                <div class="ion-padding" v-if="showContent && !blockPurchase && errorsGet.dataPhaseCurrent != false">
                    <div class="box-error">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                        <!-- <div class="cod-error">
                            Cód.: {{errorsGet.dataPhaseCurrent}}
                        </div> -->
                    </div>
                </div>

                <div class="load-blue" v-if="!showContent"></div>

                <div class="content" v-if="showContent && !blockPurchase && !errorsGet.dataPhaseCurrent">

                    <div class="no-has-quota phases" v-if="showContent && !dataPhaseCurrent.userQuota.hasQuota">
                        <h2 class="title-primary">DNE <span>T</span>oken</h2>
                        
                        <div class="box-success">
                            <p>{{getMyLanguage("token","purchase-limit-01")}}</p>
                            <p>{{getMyLanguage("token","purchase-limit-02")}}</p>
                        </div>

                        <Phases />
                    </div>

                    <!-- PHASES -->
                    <div class="ion-padding phases" v-if="showContent && dataProfile.account_status != 'Verified'">
                        <h1 class="title-primary">DNE <span>T</span>oken</h1>
                        <h3 class="alert-phase">{{ getMyLanguage("token", "token.purchase.msg-phases") }}</h3>

                        <Phases />
                    </div>

                    <div class="phase-current" v-if="showContent && dataPhaseCurrent.userQuota.hasQuota && dataProfile.account_status != 'Verified'">
                        <h4>{{dataPhaseCurrent.name}}</h4>
                        <p><strong>{{dataPhaseCurrent.finalPrice}} <span v-if="this.dataPhaseCurrent.discount > 0">({{this.dataPhaseCurrent.discount}}% OFF)</span><span v-if="this.dataPhaseCurrent.bonus > 0"> + {{dataPhaseCurrent.bonus}}% {{dataTranslation.phaseBonus}}</span></strong></p>
                        <div class="btn-showMore" @click="this.modalPhase = true"></div>
                    </div>

                    <div class="box kyc-pending" v-if="showContent && dataProfile.account_status != 'Verified'">
                        <p><strong>{{ getMyLanguage("token", "token.kycPending.alert") }}</strong></p>
                        <div class="kyc">
                            <img src="@/assets/images/icons/kyc-pending.svg">
                            <div>
                                <h6>{{ getMyLanguage("token", "token.kycPending.title") }}</h6>
                                <p>{{ getMyLanguage("token", "token.kycPending.msg") }}</p>
                                <a href="#" target="_blank" class="btn-secondary">{{ getMyLanguage("token", "token.kycPending.btn") }}</a>
                            </div>
                        </div>
                    </div>



                    <!-- BUY -->                
                    <div class="ion-padding" v-if="showContent && dataPhaseCurrent.userQuota.hasQuota && contentShow.buyPayment && dataProfile.account_status === 'Verified'">
                        <h1 class="title-primary">DNE <span>T</span>oken</h1>
                        <div class="phase-current">
                            <h4>{{dataPhaseCurrent.name}}</h4>
                            <p><strong>{{dataPhaseCurrent.finalPrice}} <span v-if="this.dataPhaseCurrent.discount > 0">({{this.dataPhaseCurrent.discount}}% OFF)</span><span v-if="this.dataPhaseCurrent.bonus > 0"> + {{dataPhaseCurrent.bonus}}% {{dataTranslation.phaseBonus}}</span></strong></p>
                            <div class="btn-showMore" @click="this.modalPhase = true"></div>
                        </div>

                        <div class="box buy-content">
                            <img src="@/assets/images/client-space/dne-coin.png" alt="DNE Token" class="dne-coins">
                            <h6>{{ getMyLanguage("token", "token.buyContent.title") }}</h6>

                            <div class="quantityBuy">
                                <div class="quantity">
                                    <div class="tooltip tooltip-less">{{ getMyLanguage("token", "token.buyContent.tooltip.min") }} 1 {{ getMyLanguage("token", "token.buyContent.tooltip.package") }}</div>
                                    <div class="tooltip tooltip-more">{{ getMyLanguage("token", "token.buyContent.tooltip.max") }} 10 {{ getMyLanguage("token", "token.buyContent.tooltip.packages") }}</div>

                                    <div class="btnQtd btn-less" :class="{disable: (dataPurchase.quantity === dataPhaseCurrent.minAmount)}" v-if="disableQuantity === false" @click="this.upSummary(dataPurchase.quantity --)" @touchstart="quantityLess()" @touchend="quantityStop()" @mousedown="quantityLess()" @mouseup="quantityStop()" @mouseout="quantityStop()">-</div>
                                    <div class="form" v-text="dataPurchase.quantity"></div>
                                    <div class="btnQtd btn-more" :class="{disable: (dataPurchase.quantity === dataPhaseCurrent.userQuota.available)}" v-if="disableQuantity === false" @click="this.upSummary(dataPurchase.quantity ++)" @touchstart="quantityMore()" @touchend="quantityStop()" @mousedown="quantityMore()" @mouseup="quantityStop()" @mouseout="quantityStop()">+</div>
                                </div>
                            </div>

                            <div class="quantityBuyRange">
                                <div class="purchased-packages">
                                    {{getMyLanguage("token","token.purchased-packages")}}
                                    {{dataPhaseCurrent.userQuota.used}}
                                </div>
                                <input type="range" :min="dataPhaseCurrent.minAmount" :max="dataPhaseCurrent.userQuota.available" step="1" v-model="dataPurchase.quantity" @touchend="upSummary(dataPurchase.quantity)"  @mouseup="upSummary(dataPurchase.quantity)">
                                <div class="quantity-min-max">
                                    <span class="minRange">{{dataPhaseCurrent.minAmount}}</span>
                                    <span class="maxRange">{{dataPhaseCurrent.userQuota.available}}</span>
                                </div>
                            </div>

                            <div class="alert-lost-token anime" v-if="(dataPurchase.quantity > (dataPhaseCurrent.userQuota.available - dataPhaseCurrent.minAmount)) && dataPurchase.quantity != dataPhaseCurrent.userQuota.available">
                                <p>
                                    <strong>{{dataPhaseCurrent.userQuota.available - dataPurchase.quantity}} </strong>
                                    {{getMyLanguage("token","token.purchase.lost-token")}}
                                </p>
                            </div>

                            <div class="summary-mobile">
                                <table class="summary-mobile-cont">
                                    <tr>
                                        <td><p><small>{{ getMyLanguage("token", "token.purchase.order-summary.quantity-tokens") }}:</small></p></td>
                                        <td class="text-right"><strong>{{summary.quantityTokenString}}</strong></td>
                                    </tr>
                                    <tr>
                                        <td><p><small>{{ getMyLanguage("token", "token.purchase.order-summary.original-price") }}:</small></p></td>
                                        <td class="text-right"><strong>{{summary.originalPriceString}}</strong></td>
                                    </tr>
                                    <tr class="promotion-price">
                                        <td><p><small>{{ getMyLanguage("token", "token.purchase.order-summary.promotion-price") }}:</small></p></td>
                                        <td class="text-right"><strong><small>({{ getMyLanguage("token", "token.purchase.order-summary.with-discount") }}{{this.dataPhaseCurrent.discount}}{{ getMyLanguage("token", "token.purchase.order-summary.percent-off") }})</small> {{summary.priceFinalString}}</strong></td>
                                    </tr>

                                    <tr v-if='methodPay === "crypto"' class="usdt">
                                        <td><p class="value-ticker"><small>TETHER USDT:</small></p></td>
                                        <td class="text-right">
                                            <strong>
                                                <small>({{summary.priceFinalString}} x {{ticker.toLocaleString("pt-PT", {style:"currency", currency:"USD"})}})</small>
                                                <span class="tether">&nbsp;{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}}</span>
                                            </strong>
                                            
                                            <small class="quote-updates">
                                                {{getMyLanguage("client-space","quote-updates")}}
                                                <strong v-if="quoteTimer >= 60"> {{quoteTimerMinutes}} <span v-if="quoteTimerMinutes > 1">{{getMyLanguage("client-space","quote-updates.minutes")}}</span><span v-if="quoteTimerMinutes == 1">{{getMyLanguage("client-space","quote-updates.minute")}}</span></strong>
                                                <strong v-if="quoteTimer < 60"> {{quoteTimer}} {{getMyLanguage("client-space","quote-updates.seconds")}}</strong>
                                            </small>
                                        </td>
                                    </tr>

                                    <tr v-if='methodPay === "swapix"' class="usdt">
                                        <td><p class="value-ticker"><small>SWAPIX USDT:</small></p></td>
                                        <td class="text-right">
                                            <strong>
                                                <small>({{summary.priceFinalString}} x {{tickerPix.toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}})</small>
                                                <span>&nbsp;{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}}</span>
                                                <!-- <span>&nbsp;{{(summary.priceFinal * ticker).toLocaleString("pt-BR", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}}</span> -->
                                            </strong>
                                            
                                            <small class="quote-updates">
                                                {{getMyLanguage("client-space","quote-updates")}}
                                                <strong v-if="quoteTimer >= 60"> {{quoteTimerMinutes}} <span v-if="quoteTimerMinutes > 1">{{getMyLanguage("client-space","quote-updates.minutes")}}</span><span v-if="quoteTimerMinutes == 1">{{getMyLanguage("client-space","quote-updates.minute")}}</span></strong>
                                                <strong v-if="quoteTimer < 60"> {{quoteTimer}} {{getMyLanguage("client-space","quote-updates.seconds")}}</strong>
                                            </small>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>


                        <!-- TOKEN PAYMENT -->
                        <div class="buy-payment" v-if="contentShow.buyPayment && dataProfile.account_status === 'Verified'">
                            <form>
                                <div class="buy-payment-cont" v-if="dataPhaseCurrent.paymentTypes.length > 1">
                                    <h3 class="title-secondary">{{getMyLanguage("tokenPayment","tokenPayment.complete-purchase")}}</h3>

                                    <!-- SELECT PAYMENT TYPE -->
                                    <ul class="select-method-payment">
                                        <li v-for="(listPaymentType,index) in dataPhaseCurrent.paymentTypes" :key="index" :class="[{hide: !listPaymentType.enabled},listPaymentType.paymentTypeCode]">
                                            <div class="select-payment-type" v-if="listPaymentType.enabled" :class='{active: methodPay === listPaymentType.paymentTypeCode}' @click='(methodPay = listPaymentType.paymentTypeCode),(ticker = listPaymentType.ticker),(dataPurchase.paymentTypeId = listPaymentType.paymentTypeId)'>
                                                <span v-if='listPaymentType.paymentTypeCode != "credit_card"'>{{listPaymentType.name}}</span>
                                                <span v-if='listPaymentType.paymentTypeCode === "credit_card"'>{{ getMyLanguage("client-space","instructions.paymentType.Credit Card") }}</span>
                                            </div>
                                        </li>
                                    </ul>


                                    <!-- CREDIT CARD -->
                                    <div :class="{error: !validateCard}" v-if='methodPay === "credit_card"'>
                                        <div class="alert-card" v-html='getMyLanguage("client-space","client-space.purchase.form.credit-card.warning")'></div>

                                        <label>
                                            {{ getMyLanguage("tokenPayment", "tokenPayment.label.cardHolder") }}
                                            <input type="text" class="form" id="cardholder" v-model="dataPurchase.cardHolder" required>
                                        </label>
                                        
                                        <label>
                                            {{ getMyLanguage("tokenPayment", "tokenPayment.label.cardNumber") }}
                                            <input type="text" class="form" id="card-number" v-mask='"#### #### #### ####"' v-model="dataPurchase.cardNumber" placeholder="____ ____ ____ ____" required>
                                        </label>

                                        <div class="expiry-cvv">                            
                                            <label class="expiry">
                                                {{ getMyLanguage("tokenPayment", "tokenPayment.label.expiry") }}
                                                <input type="text" class="form" id="expiry" v-mask='"##/##"' v-model="dataPurchase.expiry" placeholder="__/__" required>
                                            </label>
                                            
                                            <label class="cvv">
                                                {{ getMyLanguage("tokenPayment", "tokenPayment.label.cvv") }}
                                                <input type="text" class="form" id="cvv" v-mask='"###"' v-model="dataPurchase.cvv" placeholder="___" required>
                                            </label>
                                        </div>
                                    </div>



                                    <!-- CHECKS -->
                                    <div class="method-pay-animation">
                                        
                                        <!-- CHECKS SWAPIX -->
                                        <div class="checks-suapix" v-if='methodPay === "swapix"'>
                                            <div class="form-group-check">
                                                <input type="checkbox" id="swapixCheck01" class="form-check" v-model="accepts.swapix01">
                                                <label for="swapixCheck01" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-swapix01") }}</small></label>
                                            </div>

                                            <div class="form-group-check">
                                                <input type="checkbox" id="swapixCheck02" class="form-check" v-model="accepts.swapix02">
                                                <label for="swapixCheck02" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-swapix02") }}</small></label>
                                            </div>
                                            
                                            <div class="form-group-check">
                                                <input type="checkbox" id="swapixCheck03" class="form-check" v-model="accepts.swapix03">
                                                <label for="swapixCheck03" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-swapix03") }}</small></label>
                                            </div>
                                        </div>

                                        <div class="form-group-check">
                                            <input type="checkbox" id="acceptNotify" class="form-check" v-model="accepts.Conditions">
                                            
                                            <label for="acceptNotify" class="form-check-label">
                                                <small>{{ getMyLanguage("tokenPayment", "tokenPayment.alert-reserve.accept") }}</small>
                                            </label>
                                        </div>

                                        <div class="form-group-check">
                                            <input type="checkbox" id="acceptTerms" class="form-check" v-model="accepts.Terms">
                                           
                                            <label for="acceptTerms" class="form-check-label">
                                                <small>
                                                    {{ getMyLanguage("tokenPayment", "client-space.confirm-accordance-terms") }}
                                                    </small>
                                            </label>
                                            
                                            <a href="https://dne.international/en/terms-and-conditions" target="_blank" class="btn-learn" v-html='getMyLanguage("register","activate.read.terms")'></a>
                                        </div>


                                        <!-- OPEN MODAL CONFIRM -->
                                        <div v-if='methodPay != "swapix"'>
                                            <input type="hidden" v-model="dataPurchase.phaseProductId">

                                            <div class="btn-primary disabled" v-if='(methodPay != "crypto" && methodPay != "swapix") && (accepts.Conditions != true || accepts.Terms != true)'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{summary.priceFinalString}}</small></div>
                                            <div @click="openModalConfirm()" expand="block" class="btn-primary" v-if='(methodPay != "crypto" && methodPay != "swapix") && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{summary.priceFinalString}}</small></div>

                                            <div class="btn-primary btn-pay-crypto disabled" v-if='(methodPay === "crypto" || methodPay === "swapix") && (accepts.Conditions != true || accepts.Terms != true)'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT <small>({{summary.priceFinalString}})</small></small></div>
                                            <div @click="openModalConfirm()" expand="block" class="btn-primary btn-pay-crypto" v-if='(methodPay === "crypto" || methodPay === "swapix") && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT <small>({{summary.priceFinalString}})</small></small></div>
                                        </div>
                                        
                                        <div v-if='methodPay === "swapix"'>
                                            <div class="btn-primary btn-pay-crypto disabled" v-if='accepts.swapix01 != true || accepts.swapix02 != true || accepts.swapix03 != true || accepts.Conditions != true || accepts.Terms != true'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} (PIX)</small></div>
                                            <div @click="openModalConfirm()" expand="block" class="btn-primary btn-pay-crypto" v-if='accepts.swapix01 && accepts.swapix02 && accepts.swapix03 && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} (PIX)</small></div>
                                        </div>



                                        <!-- <div class="btn-primary disabled" v-if='(methodPay != "crypto" && methodPay != "swapix") && !stepBuy.load && (accepts.Conditions != true || accepts.Terms != true)'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{summary.priceFinalString}}</small></div>
                                        <div @click="openModalConfirm()" expand="block" class="btn-primary" v-if='(methodPay != "crypto" && methodPay != "swapix") && !stepBuy.load && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{summary.priceFinalString}}</small></div>

                                        <div class="btn-primary btn-pay-crypto disabled" v-if='(methodPay === "crypto" || methodPay === "swapix") && !stepBuy.load && (accepts.Conditions != true || accepts.Terms != true)'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT <small>({{summary.priceFinalString}})</small></small></div>
                                        <div @click="openModalConfirm()" expand="block" class="btn-primary btn-pay-crypto" v-if='(methodPay === "crypto" || methodPay === "swapix") && !stepBuy.load && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT <small>({{summary.priceFinalString}})</small></small></div> -->
                                    </div>


                                    <!-- PAYMENT CONDITIONS -->
                                    <div class="msg-payment-condition box">
                                        <div v-for="(listConditions,index) in dataPhaseCurrent.paymentTypes" :key="index">
                                            <div v-if='methodPay === listConditions.paymentTypeCode' v-html='getMyLanguage("client-space",listConditions.paymentTypeCode + ".text")'></div>
                                        </div>
                                    </div>

                                    <div class="alert-reserve" v-html='getMyLanguage("tokenPayment", "tokenPayment.alert-reserve")'></div>
                                </div>
                            </form>


                            <div class="btn-center">
                                <a :href='"https://dne.international/WhitePaper-" + flagSelected + ".pdf"' target="_blank" class="btn-whitepaper">
                                    <span>Whitepaper</span>
                                    <small>PDF Download</small>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </ion-content>

            <Footer />


            <!-- MODAL CONFIRM -->
            <div class="modal modal-confirm" v-if="modalConfirm">
                <div class="box cont-modal" v-if="!threeDSecurity">
                    <div class="close-modal" @click="modalConfirm = false"></div>
                
                    <div class="scroll-vertical">
                        <h2>Terms and conditions of the purchase lots of DNE Token in the Private Sale:</h2>
                        <!-- <p>AGREEMENT - LAST UPDATED: JUNE 20, 2022</p> -->
                        
                        <h3>Terms and Conditions</h3>
                            <p>These Terms and Conditions regulate the purchase and sale, access and use of the Crypto TOKEN “DNE” during a Private Sale, whose digital code belongs to <strong>DNE INTERNATIONAL SAGL</strong>, which is responsible for operating and managing the operation.</p>
                            <p>Terms and conditions to buy lots of DNE TOKEN during the Private Sale – a situation in which <strong>“DNE TOKEN”</strong> is sold to only a small group of customers, rather than on an open market.</p>
                            
                            <h3>Considerations:</h3>
                            <p>- Lots - The DNE TOKEN is a digital unit. One lot of DNE TOKEN consists of 180 token units, at a price of €0,50 per unit. </p>
                            <p>- Private sale – During the “Private Sale” phase there will be available for sale the total amount of 60,000 Lots of 180 units, which can be extended to up to 100,000 lots depending on demand, at a price of €90 per lot with a 40% discount corresponding to €54 per lot, in return for keeping the lots in stake for 18 months.</p>
                            <p>- Only DNE users can buy DNE Tokens, under the following rights and conditions:</p>
                            <p>- Private sale starts from June 23, 2022, which will last 90 days (ninety days), the DNE User will have the right to purchase the tokens - DNE TOKEN - with a 40% discount, with the obligation to keep the respective tokens for a period of 18 months, counting from the date of signature of this agreement referred to herein.</p>
                            <p>- This discount cannot be accumulated with others.</p>
                            <p>- The minimum purchase order for each DNE User is 5.400 tokens, corresponding to 30 (thirty) lots and a cumulative maximum of 250 (two hundred and fifty) lots, corresponding to 45.000 tokens for each purchasing entity or person.</p>
                            <p>- Digital wallets will be made available at the start of the ICO.</p>

                            <h3>Use, access and transfer/send:</h3>
                            <p>- During the first stage, you are not allowed to close the Plan or Use, transfer or send DNE TOKEN maintained in the same prior to its expiration. In the event that you submit a request to Use, transfer or send your DNE TOKEN bought with the above mentioned conditions, being only one or all of the token batches, and / or request to close your wallet Account prior to the expiration of this Plan, you agree that we shall return the entire portfolio of the wallet referring to the purchase made at this stage, minus the 40% discount, plus a 25% penalty for breach of contract.</p>

                            <h3>Payment conditions:</h3>
                            <p>- Payment for the purchase of DNE Token lots will be made by bank transfer or USDT (Tether) (from network Ethereum mainnet, Polygon and Binance Smart chain). </p>
                            <p>- Payment by SEPA/SWIFT transfer is available for European Union countries and others that have integration (check with your bank for this option).</p>
                            <p>- If the payment method is a SEPA transfer, the deadline for its accomplishment will be up to 3 days after receiving payment proof, otherwise the purchase and respective reference will be cancelled.</p>
                            <p>- Payment by SEPA transfer has a confirmation period of up to 72 hours.</p>
                            <p>- If the payment method is a SWIFT transfer, the deadline for its accomplishment will be up to 5 days after receiving payment proof, otherwise the purchase and respective reference will be cancelled.</p>
                            <p>- Payment by SWIFT transfer has a confirmation period of up to 5 days.</p>
                            <p>- Payment via SEPA/SWIFT must be made by a wire transfer through a bank account in which the holder is the same as the holder of the purchase of the DNE Token lots. If not, the amount will be refunded, deducted from the return fee.</p>
                            <p>- Bank transfers must always contain the reference of the purchase of DNE Token lots.</p>
                            <p>- If the payment method is USDT (Tether) the deadline for its accomplishment will be up to 24 hours after receiving payment proof, otherwise the purchase and respective reference will be cancelled. This payment method has a confirmation period on the Blockchain of 12 hours, and confirmation is only accepted and completed after 15 network confirmations. Failure to confirm this leads to the cancellation of the purchase.</p>
                            <p>- DNE International Sagl reserves the right at any time to change payment methods, and these changes will never be for purchases already confirmed. </p>
                            <p>The purchase of DNE Token lots is only valid after confirmation of your good payment. While the payment is pending, it does not guarantee the purchase of the DNE Token lots.</p>
                            <p>After validating your purchase, you will receive the respective agreement duly numbered and registered on the Blockchain in your backoffice.</p>
                            <p>The purchase of DNE Token lots is limited to the amount available.</p>
                            
                            <h3>Miscellaneous Provisions:</h3>
                            <p>This transaction is vat exempt; Capital gains may be subject to declaration and taxes; You should consult your tax advisor and/or your country regulations.</p>
                            <p>The information provided on this document does not constitute investment advice, financial advice, trading advice, or any other sort of advice and you should not treat any of the company website's content as such. DNE International SAGL does not recommend that any cryptocurrency should be bought, sold, or held by you. Do conduct your own due diligence and consult your financial advisor before making any investment decisions.</p>
                            <p>Questions and inquiries regarding the Terms and Conditions of Purchase should be sent to support@dne.international</p>
                            <p>In the resolution of doubts arising from the fulfillment of this Agreement, the provisions in force in this type of agreement will be applied and the parties elect as competent the jurisdiction of the Courts of Lugano, Switzerland, with express waiver of any other.</p>
                    </div>

                    <div class="box-error" v-if="showContent && errorsPost.dataPurchase != false">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                        <div class="cod-error">
                            Cód.: {{errorsPost.dataPurchase}}
                        </div>
                    </div>

                    <div class="confirm-purchase" v-if="showContent">
                        <div class="form-group-check" v-if='methodPay != "crypto" && methodPay != "swapix"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{summary.priceFinalString}}</strong></small></label>
                        </div>
                        
                        <div class="form-group-check" v-if='methodPay === "crypto"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} TETHER USDT</strong>&nbsp;({{summary.priceFinalString}})</small></label>
                        </div>
                        
                        <div class="form-group-check" v-if='methodPay === "swapix"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} (PIX)</strong></small></label>
                        </div>
                        
                        <div class="btn-primary disabled" v-if="!accepts.Confirm">{{getMyLanguage("client-space","modal-confirm.btn-confirm")}}</div>
                        <div @click="purchase()" class="btn-primary" v-if="accepts.Confirm && !stepBuy.load">{{getMyLanguage("client-space","modal-confirm.btn-confirm")}}</div>                        
                        <div class="btn-primary load" v-if="stepBuy.load"></div>
                    </div>
                    
                    <!-- <div class="confirm-purchase" v-if="showContent && errorsPost.dataPurchase === false">
                        <div class="form-group-check" v-if='methodPay != "crypto"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{summary.priceFinalString}}</strong></small></label>
                        </div>
                        
                        <div class="form-group-check" v-if='methodPay === "crypto"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} TETHER USDT</strong>&nbsp;({{summary.priceFinalString}})</small></label>
                        </div>
                        
                        <div class="btn-primary disabled" v-if="!accepts.Confirm">{{getMyLanguage("client-space","modal-confirm.btn-confirm")}}</div>
                        <div @click="validateFormPay()" class="btn-primary" v-if="accepts.Confirm && !stepBuy.load">{{getMyLanguage("client-space","modal-confirm.btn-confirm")}}</div>                        
                        <div class="btn-primary load" v-if="stepBuy.load"></div>
                    </div> -->
                </div>
            </div>
            <div class="overlayModal" v-if="modalConfirm"></div>


            <!-- MODAL PHASE -->
            <div class="modal" v-if="this.modalPhase == true">
                <div @click="this.modalPhase = false" class="close-modal"></div>

                <div class="box cont-modal phase-modal">
                    <div class="scroll-vertical">
                        <h3>
                            {{dataPhaseCurrent.name}}
                            <small>{{ dateTimePhase(dataPhaseCurrent.startDate) }} / {{ dateTimePhase(dataPhaseCurrent.endDate) }}</small>
                        </h3>
                        <div class="phase">
                            <p><span>{{dataTranslation.phaseDiscount}}:</span> <strong>{{dataPhaseCurrent.discount}}%</strong></p>
                            <p><span>{{dataTranslation.phaseBonus}}:</span> <strong>{{dataPhaseCurrent.bonus}}%</strong></p>
                            <p><span>{{dataTranslation.phaseMinAmount}}:</span> <strong>{{dataPhaseCurrent.minAmount}} {{dataTranslation.phasePackages}}</strong></p>
                            <p><span>{{dataTranslation.phaseMaxnAmount}}:</span> <strong>{{dataPhaseCurrent.maxAmount}} {{dataTranslation.phasePackages}}</strong></p>
                            <p><span>{{dataTranslation.phaseDateStart}}:</span> <strong>{{ dateTime(dataPhaseCurrent.startDate) }}</strong></p>
                            <p><span>{{dataTranslation.phaseDateEnd}}:</span> <strong>{{ dateTime(dataPhaseCurrent.endDate) }}</strong></p>
                            <p><span>{{dataTranslation.phaseTimeStake}}:</span> <strong>{{dataPhaseCurrent.stakeMo}} {{dataTranslation.phaseMonths}}</strong></p>
                            <p><span>{{dataTranslation.phaseMaxSelling}}:</span> <strong>{{dataPhaseCurrent.maxQuantity}} {{dataTranslation.phasePackages}}</strong></p>
                        </div>

                        <div class="btn-center">
                            <a class="btn-whitepaper">
                                <span>Whitepaper</span>
                                <small>PDF Download</small>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlayModal" v-if="modalPhase"></div>

        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController } from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import {loadStripe} from '@stripe/stripe-js';
    // const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PK);
    const stripePromise = loadStripe("pk_test_51LrJRRD3Dh6NvQyPiUfCZGuQ4oz4j3hV9fxgykvrTWHzcOySpdfHYPJTIOScz2vFDwDL4LM6LmBG4nsEg82QLagR007ONqwqGm");
    let cancelThreeDS = ""

    import apiInternational from '@/config/apiInternational.js';
    import moment from 'moment';

    import {mask} from 'vue-the-mask'

    import Header from '@/components/dneinternational/Header.vue';
    import Phases from '@/components/dneinternational/Phases.vue'
    import Footer from '@/components/dneinternational/Footer.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            Header,
            Phases,
            Footer
        },
        directives: {mask},
        data () {
            return {
                showContent: false, 
                dataProfile: "",
                dataPhaseCurrent: [],
                dataTranslation: {},
                dataProducts: [],
                dataSummary: {},
                dataPurchase: {},
                disableQuantity: false,

                validateCard: true,
                threeDSecurity: false,
                scaLink: "",
                redirectTo: "",

                methodPay: "sepa_swift",
                ticker: '',
                tickerPix: '',
                quoteTimer: 0,

                accepts: {
                    swapix01: false,
                    swapix02: false,
                    swapix03: false,
                    Conditions: false,
                    Terms: false,
                    Confirm: false
                },

                errorsPost: {
                    dataPurchase: false
                },

                errorsGet: {
                    dataProfile: false,
                    dataPhaseCurrent: false
                },

                summary: {},
                modalPhase: false,
                modalConfirm: false,
                contentShow: {
                    'accountPending': false,
                    'buyPayment': false
                },
                stepBuy: {
                    'load': false
                },
                blockPurchase: false
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
            
            goPayment(){
                this.stageBuy.buyContent = false
                this.stageBuy.buyPayment = true
                // window.location.href = this.flagSelected + '/token-payment/' + this.dataProfile.id
            },

            dateTime(value) {
                return moment(value).locale(this.flagSelected).format('ll');
            },

            dateTimePhase(value) {
                return moment(value).locale(this.flagSelected).format('DD MMM');
            },

            quantityLess(){
                this.interval = setInterval(() => {
                    setTimeout(() => {
                            this.upSummary(this.dataPurchase.quantity -= 1)
                    }, 200);
                }, 150)
            },

            quantityMore(){
                this.interval = setInterval(() => {
                    setTimeout(() => {
                            this.upSummary(this.dataPurchase.quantity += 1)
                    }, 200);
                }, 150)
            },

            quantityStop(){
                clearInterval(this.interval)
            },

            transPurchaseTokenString(){
                const phasePackage = this.getMyLanguage("purchase-token", "purchase-token.content.phase.package")
                const phaseDiscount = this.getMyLanguage("purchase-token", "purchase-token.content.phase.discount")
                const phaseBonus = this.getMyLanguage("purchase-token", "purchase-token.content.phase.bonus")
                const phaseMinAmount = this.getMyLanguage("purchase-token", "purchase-token.content.phase.minAmount")
                const phaseMaxnAmount = this.getMyLanguage("purchase-token", "purchase-token.content.phase.maxAmount")
                const phaseDateStart = this.getMyLanguage("purchase-token", "purchase-token.content.phase.dateStart")
                const phaseDateEnd = this.getMyLanguage("purchase-token", "purchase-token.content.phase.dateEnd")
                const phaseTimeStake = this.getMyLanguage("purchase-token", "purchase-token.content.phase.timeStake")
                const phaseMaxSelling = this.getMyLanguage("purchase-token", "purchase-token.content.phase.maxSelling")
                const phasePurchaseNow = this.getMyLanguage("purchase-token", "purchase-token.content.phase.purchaseNow")
                const phasePackages = this.getMyLanguage("purchase-token", "purchase-token.content.phase.packages")
                const phaseMonths = this.getMyLanguage("purchase-token", "purchase-token.content.phase.months")
                
                const digitalNewEconomy = this.getMyLanguage("purchase-token", "purchase-token.content.digital-new-economy")
                
                this.dataTranslation.phasePackage = phasePackage
                this.dataTranslation.phaseDiscount = phaseDiscount
                this.dataTranslation.phaseBonus = phaseBonus
                this.dataTranslation.phaseMinAmount = phaseMinAmount
                this.dataTranslation.phaseMaxnAmount = phaseMaxnAmount
                this.dataTranslation.phaseDateStart = phaseDateStart
                this.dataTranslation.phaseDateEnd = phaseDateEnd
                this.dataTranslation.phaseTimeStake = phaseTimeStake
                this.dataTranslation.phaseMaxSelling = phaseMaxSelling
                this.dataTranslation.phasePurchaseNow = phasePurchaseNow
                this.dataTranslation.phasePackages = phasePackages
                this.dataTranslation.phaseMonths = phaseMonths
                
                this.dataTranslation.digitalNewEconomy = digitalNewEconomy
            },

            accountStatusActivated(){
                if ( this.dataProfile.account_status === "Verified" ){
                    this.contentShow.buyPayment = true
                } else{
                    this.contentShow.accountPending = true
                }
            },

            // validateFormPay(){
            //     this.stepBuy.load = true
            //     this.purchase();
            // },

            changeCurrent(){
                apiInternational.get('/api/v1/ico/phase/current')
                .then(response => {
                    this.dataPhaseCurrent = response.data
                    
                    setTimeout(() => {
                        this.showWallets = true
                        this.showContent = true
                        this.load = false

                        this.ticker = this.dataPhaseCurrent.paymentTypes.filter(paymentTypes => paymentTypes.paymentTypeCode === "crypto")[0].ticker
                        this.tickerPix = this.dataPhaseCurrent.paymentTypes.filter(paymentTypes => paymentTypes.paymentTypeCode === "swapix")[0].ticker
                    }, 1000);
                })
            },

            upSummary(){
                const price = this.dataPhaseCurrent.price
                const quantityTokens = this.dataPhaseCurrent.quantity
                const bonus = this.dataPhaseCurrent.bonus
                const discount = this.dataPhaseCurrent.discount
                const min = this.dataPhaseCurrent.minAmount
                const max = this.dataPhaseCurrent.userQuota.available

                if ( this.dataPurchase.quantity <= min ){
                    this.dataPurchase.quantity = min
                    this.dataPurchase.quantity = min
                }
                
                if ( this.dataPurchase.quantity >= max){
                    this.dataPurchase.quantity = max
                    this.dataPurchase.quantity = max
                }

                
                this.summary.originalPrice = price * this.dataPurchase.quantity
                this.summary.quantityToken = quantityTokens * this.dataPurchase.quantity
                this.summary.priceFinal = (price * this.dataPurchase.quantity)-((price * this.dataPurchase.quantity)*(discount / 100))
                this.summary.totalToken = (this.dataPurchase.quantity * quantityTokens) * (1 + (bonus / 100));
              
                this.quantityPurchase()
            },

            purchase(){
                this.load = true
                const purchase = this.dataPurchase;

                apiInternational.post("/api/v1/ico/token_sale/purchase/", purchase)
                .then(response => {
                    if ( response.data.success ){
                        window.location.href = '/' + response.data.returnUrl

                    } else if ( !response.data.success && response.data.status === "requires_action" && response.data.nextAction.type === "use_stripe_sdk" ){
                        
                        stripePromise.then(responseStripe => {
                            responseStripe.confirmCardPayment(response.data.nextAction.clientSecret)
                            .then(result => {
                                if ( result.error ){
                                    cancelThreeDS = result.error.type
                                    console.log(cancelThreeDS)
                                    window.location.href = '/' + this.flagSelected + '/international/token-payment-error?error=' + result.error.type
                                    
                                } else if ( result.paymentIntent.status === "succeeded" ){
                                    window.location.href = '/' + response.data.returnUrl
                                }
                            })
                        })
                        .catch(error => {
                            window.location.href = '/' + this.flagSelected + '/international/token-payment-error?error=' + error
                        })
                    } else {
                        window.location.href = '/' + this.flagSelected + '/international/token-payment-error?error=error_3ds'
                    }
                })
                .catch(error => {
                    window.location.href = '/' + this.flagSelected + '/international/token-payment-error?error=' + error.response.data[0]
                })
            },

            checkPurchaseCreditCard(){
                window.location.href = '/' + this.redirectTo
            },

            quantityPurchase() {
                let qtdTokens = this.summary.quantityToken
                let totalTokens = this.summary.totalToken
                let priceOriginal = this.summary.originalPrice
                let priceFinal = this.summary.priceFinal
                
                qtdTokens = qtdTokens.toLocaleString()
                totalTokens = totalTokens.toLocaleString()
                priceOriginal = priceOriginal.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})
                priceFinal = priceFinal.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})
                
                this.summary.quantityTokenString = qtdTokens.toLocaleString()
                this.summary.totalTokenString = totalTokens.toLocaleString()
                this.summary.originalPriceString = priceOriginal.toLocaleString()
                this.summary.priceFinalString = priceFinal.toLocaleString()
            },

            countDownQuote() {                
                if(this.quoteTimer > 0) {
                    setTimeout(() => {
                        this.quoteTimer -= 1

                        if ( this.quoteTimer >= 60 ){
                            this.quoteTimerMinutes = this.quoteTimer / 60
                            this.quoteTimerMinutes = String(this.quoteTimerMinutes).split(".")[0]
                        }

                        this.countDownQuote()
                    }, 1000)
                } else{
                    this.changeCurrent()
                    this.dataPhaseCurrent.paymentTypes[2].ticker = this.ticker
                    this.upSummary()
                    this.quoteTimer = 130
                    this.countDownQuote()
                }
            },

            openModalConfirm(){
                if ( this.methodPay === "credit_card" ){
                    if ( (this.dataPurchase.cardHolder.length < 5 || this.dataPurchase.cardHolder == null) || (this.dataPurchase.cardNumber.length < 16 || this.dataPurchase.cardNumber == null) || (this.dataPurchase.expiry.length < 2 || this.dataPurchase.expiry == null) || (this.dataPurchase.cvv.length < 3 || this.dataPurchase.cvv == null) ){
                        this.validateCard = false
                        this.errorCreditCard()
                    } else{
                        this.validateCard = true
                        this.modalConfirm = true
                    }
                } else {
                    this.modalConfirm = true
                    this.errorsPost.dataPurchase = false
                }
            },

            async errorCreditCard() {
                const toast = await toastController.create({
                    message: this.getMyLanguage("errors", "errors.credit_card"),
                    color: "danger",
                    duration: 5000,
                })
                return toast.present();
            },
        },


        async mounted () {
            this.flagUrl()

            this.dataProfile = JSON.parse(localStorage.getItem("profile"))
            
            this.accountStatusActivated();

            const tokenValue = localStorage.getItem('accessToken')

            await apiInternational.get('/api/v1/ico/phase/current')
            .then(response => {
                this.dataPhaseCurrent = response.data
                
                setTimeout(() => {
                    this.showWallets = true
                    this.showContent = true
                    this.load = true

                    this.ticker = this.dataPhaseCurrent.paymentTypes.filter(paymentTypes => paymentTypes.paymentTypeCode === "crypto")[0].ticker

                    if ( this.dataProfile.profile_country_name === "Brasil" ){
                        this.tickerPix = this.dataPhaseCurrent.paymentTypes.filter(paymentTypes => paymentTypes.paymentTypeCode === "swapix")[0].ticker
                    }

                }, 1000);
            })
            .catch(error => {
                this.errorsGet.dataPhaseCurrent = error.response.data
                this.errorsGet.dataPhaseCurrent = JSON.stringify(error.response.status)

                if ( error.response.status === 403 ) {
                    this.showWallets = false
                    window.location.href = '/' + this.flagSelected + "/client-space-consent"
                }
                
                if ( error.response.status === 409 ) {
                    if ( error.response.data === "account_must_have_kyc" ){
                        window.location.href = '/' + this.flagSelected + "/account/kyc"
                    }

                    if ( error.response.data === "account_must_have_compliance" ){
                        window.location.href = '/' + this.flagSelected + "/account/compliance"
                    }

                    if ( error.response.data === "account_must_have_company_compliance" ) {
                        window.location.href = "/" + this.flagSelected + "/account/compliance-company";
                    }

                    if ( error.response.data === "account_must_be_manualy_checked" ){
                        window.location.href = '/' + this.flagSelected + "/account/pending"
                    }
                }
                
                if( error.response.status === 401 && tokenValue.length > 0 ){
                    localStorage.removeItem('accessToken');
                    window.location.href = '/' + this.flagSelected + "/login"
                }
            })

            this.dataPurchase.accountId = this.dataProfile.accountId
            this.dataPurchase.phaseId = this.dataPhaseCurrent.id
            this.dataPurchase.quantity = this.dataPhaseCurrent.minAmount
            // this.dataPurchase.cardHolder = ((this.dataProfile.personName) + " " + (this.dataProfile.personSurname))
            
            this.dataPurchase.paymentTypeId = "732e682f-a99f-4914-b2b4-c56351b12ca6"

            this.dataPurchase.cardHolder = ""
            this.dataPurchase.cardNumber = ""
            this.dataPurchase.expiry = ""
            this.dataPurchase.cvv = ""

            this.summary.quantityTokenString = this.dataPhaseCurrent.quantity
            this.summary.originalPriceString = this.dataPhaseCurrent.price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})
            this.summary.priceFinalString = this.dataPhaseCurrent.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})
            this.summary.totalTokenString = this.dataPhaseCurrent.totalAmount

            const urlParams = new URLSearchParams(window.location.search);
            const phasePurchaseIdParams = urlParams.get('phasePurchaseId');
            const quantityParams = urlParams.get('quantity');

            if ( urlParams != '' ){
                this.dataPurchase.phasePurchaseId = phasePurchaseIdParams
                this.dataPurchase.quantity = quantityParams
                this.disableQuantity = true
            }

            this.transPurchaseTokenString();

            this.upSummary();

            this.quoteTimer = 130
            this.countDownQuote();

            await apiInternational.get('/api/v1/ico/token_sale/purchase')
            .then(response => {
                console.log(response)
                // console.clear()
                this.showContent = true
            })
            .catch(error => {                
                if (error.response.status === 409) {
                    if (error.response.data === "account_must_have_kyc") {
                        window.location.href = "/" + this.flagSelected + "/account/kyc";
                    }

                    if (error.response.data === "account_must_have_compliance") {
                        window.location.href =
                        "/" + this.flagSelected + "/account/compliance";
                    }

                    if ( error.response.data === "account_must_have_company_compliance" ) {
                       window.location.href = "/" + this.flagSelected + "/account/compliance-company";
                    }

                    if (error.response.data === "account_must_be_manualy_checked") {
                        window.location.href = "/" + this.flagSelected + "/account/pending";
                    }

                    if (error.response.data === "wrong_account_type") {
                        this.showContent = true
                        this.blockPurchase = true
                        console.clear()
                    }
                }
                
                if ( error.response.status === 403 ) {
                    window.location.href = '/' + this.flagSelected + "/platform/consent/ask?scope=3fa85f64-5717-4562-b3fc-2c963f66afa6&destiny=" + window.location.pathname
                } else if ( error.response.status === 401 && tokenValue.length > 0 ){
                    localStorage.removeItem('accessToken');
                    window.location.href = '/' + this.flagSelected + "/login"
                }
            })
        }
    }
</script>


<style scoped>
    .load-blue{margin-top: 100px;}

    .phases{margin-top: 30px; margin-bottom: 40px;}
    .alert-phase{font-size: 15px; text-align: center; color: var(--color-primary); margin-bottom: 0;}

    .phase-current{padding: 16px 16px 0 16px; background: url('~@/assets/images/bg-blue.png'); border-radius: 12px; text-align: center; position: relative;}
    .phase-current h4{font-size: 20px; font-family: var(--font-primary-semibold); color: var(--color-text-tertiary); margin: 0 0 5px 0;}
    .phase-current p{font-size: 12px; font-weight: bold; color: var(--color-secondary); margin: 0;}
    .phase-current .btn-showMore{display: inline-block; width: 40px; height: 40px; background: url('~@/assets/images/icons/show-more.svg') no-repeat center; background-size: 20px auto;}

    .no-has-quota{padding: 0 16px;}
    .no-has-quota .box-success{max-width: 500px; margin: 30px auto 50px; background: url('~@/assets/images/bg-green.png') no-repeat; background-size: cover; border-radius: 12px !important; padding: 30px 16px;}
    .no-has-quota .box-success p{color: var(--color-text-tertiary); text-align: center;}
    .no-has-quota .box-success .cod-error{font-size: 60%; color: var(--color-text-tertiary); text-align: center; opacity: .4;}

    .kyc-pending{padding: 20px 16px; border-radius: 0 0 12px 12px;}
    .kyc-pending p strong{display: block; text-align: center; color: var(--color-primary); padding: 0 30px; font-size: 13px; font-weight: bold;}
    .kyc-pending .kyc{display: flex; justify-content: center; align-items: center; margin-top: 20px;}
    .kyc-pending .kyc img{width: auto; height: 82px; margin-right: 20px;}
    .kyc-pending div h6{font-size: 20px; font-family: var(--font-primary-semibold); color: var(--color-pending); margin: 0 0 5px;}
    .kyc-pending div p{font-size: 11px; color: var(--color-primary); margin: 0;}
    .kyc-pending div .btn-secondary{margin: 10px 0 0 0;}

    .buy-content .dne-coins{display: block; width: auto; height: 70px; margin: 0 auto 10px;}

    .buy-content{border-radius: 0 0 12px 12px; padding-top: 30px; padding-bottom: 20px;}
    .buy-content h6{font-size: 14px; font-family: var(--font-primary-semibold); color: var(--color-primary); margin: 10px 0; text-align: center;}
    .buy-content .quantityBuy{text-align: center;}
    .buy-content .quantityBuy .quantity{position: relative; display: flex; align-items: center; justify-content: center; margin: 10px 0;}
    .buy-content .quantityBuy .quantity .tooltip{display: none; position: absolute; top: -33px; left: 50%; transform: translateX(-50%); background: var(--color-pending); font-size: 14px; line-height: 20px; color: var(--color-text-tertiary); padding: 5px 10px; border-radius: 10px; white-space: nowrap;}
    .buy-content .quantityBuy .quantity .tooltip:after{content: ''; display: block; position: absolute; left: 50%; bottom: -10px; transform: translateX(-50%); width: 0;  height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 10px solid var(--color-pending);}
    .buy-content .quantityBuy .quantity .btnQtd{width: 40px; height: 40px; line-height: 40px; text-align: center; background: var(--color-primary); color: var(--color-text-tertiary); font-size: 24px; cursor: pointer; transition: .3s; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
    .buy-content .quantityBuy .quantity .btnQtd.btn-less{border-radius: 12px 0 0 12px;}
    .buy-content .quantityBuy .quantity .btnQtd.btn-more{border-radius: 0 12px 12px 0;}
    .buy-content .quantityBuy .quantity .btnQtd.disable{opacity: .4;}
    .buy-content .quantityBuy .quantity .form{margin-bottom: 0; font-size: 18px; width: 80px; height: 40px; line-height: 40px; background: #eee; border-radius: 0; font-family: var(--font-primary-semibold);}

    .summary-mobile{background: #f5f5f5; padding: 2px 15px; border-radius: 12px; margin: 30px auto 0; width: 100%; max-width: 400px;}
    .summary-mobile .summary-mobile-cont{width: 100%; max-width: 360px; border: none; color: var(--color-primary); font-size: 13px;}
    .summary-mobile .summary-mobile-cont tr td{line-height: 16px; padding: 5px 0;}
    .summary-mobile .summary-mobile-cont tr td{border-bottom: 1px solid rgba(31,53,97,.2);}
    .summary-mobile .summary-mobile-cont tr:last-child td{border-bottom: none;}
    .summary-mobile .summary-mobile-cont tr td p{padding: 10px 0; margin: 0;}
    .summary-mobile .summary-mobile-cont tr td small{font-size: 11px;}
    .summary-mobile .summary-mobile-cont tr td strong{display: block; text-align: right;}
    .summary-mobile .summary-mobile-cont tr td strong small{display: inline-block; margin-left: 5px;}
    .summary-mobile .summary-mobile-cont .promotion-price strong small{color: var(--color-secondary);}

    .summary-mobile .summary-mobile-cont .usdt td > small{display: block; text-align: right; font-style: italic; font-size: 11px; line-height: 13px; margin-top: 2px;}
    .summary-mobile .summary-mobile-cont .usdt td > small strong{display: inline-block;}
    .summary-mobile .summary-mobile-cont .usdt td .tether{display: inline-block; padding-right: 12px; position: relative;}
    .summary-mobile .summary-mobile-cont .usdt td .tether:after{content: ''; display: block; width: 14px; height: 16px; margin-left: 2px; background: url('~@/assets/images/icons/payment-type-tether.svg') no-repeat center right; background-size: 9px auto; position: absolute; right: 0; top: 0;}

    .form-group-check{width: 100%; margin: 15px 0;}
    .form-check-label small{font-size: 11px;}
    .btn-learn{display: block; font-size: 11px; text-decoration: underline; color: var(--color-text-tertiary); margin-left: 32px; margin-top: -5px;}

    .btn-primary{flex-direction: column; height: 56px; margin-top: 20px;}
    .btn-primary:after{display: none;}
    .btn-primary.disabled{opacity: .5; filter: blur(1px);}

    .btn-pay-crypto{}
    .btn-pay-crypto small small{font-size: 85%;}
    
    
    /* TOKEN PAYMENT */
    .summary-pay{}
    .summary-pay h6{font-size: 20px; margin-bottom: 20px; color: var(--color-primary);}
    .summary-pay p{margin-bottom: 0;}
    .summary-pay p strong{color: var(--color-secondary);}

    .buy-payment{margin: -40px 0 30px 0;}
    .buy-payment .title-secondary{margin: 30px 0 !important; color: var(--color-secondary);}


    /* RANGE */
    .quantityBuyRange{margin: 30px auto 0; width: 100%; max-width: 400px;}
    .quantityBuyRange input{margin: 3px 0;}
    .quantityBuyRange .purchased-packages{font-size: 12px; font-family: var(--font-primary-semibold); color: var(--color-secondary);}
    .quantityBuyRange .quantity-min-max{display: flex; justify-content: space-between;}
    .quantityBuyRange .quantity-min-max span{display: block; font-size: 10px; line-height: 12px; color: var(--color-quaternary);}


    /* ALERT LOST TOKEN */
    .alert-lost-token{position: relative; border: 2px solid rgba(235,103,51,0.25);background: rgba(235,103,51,0.1); margin: 10px auto; border-radius: 100px; width: 100%; max-width: 400px;}
    .alert-lost-token p{margin: 0; font-size: 12px; text-align: center; line-height: 16px; padding: 7px 20px;}


    /* SELECT METHOD PAYMENT */
    ul.select-method-payment{display: flex; flex-wrap: wrap; justify-content: center; width: 100%; margin: 10px 0 30px 0; padding: 0;}
    ul.select-method-payment li{display: flex; align-items: flex-start; cursor: pointer; margin-bottom: 16px; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
    ul.select-method-payment li .select-payment-type{width: 100%; padding: 10px; border-radius: 12px; color: var(--color-text-tertiary); text-transform: uppercase; display: flex; flex-direction: column; align-items: center; justify-content: flex-end; text-align: center; position: relative;}
    ul.select-method-payment li .select-payment-type:before{content: ''; display: block; width: 50px; height: 50px; border-radius: 50%; background: #eee; background-size: 50% auto !important; border: 2px solid #ddd; margin-bottom: 10px;}
    ul.select-method-payment li .select-payment-type.active{color: var(--color-text-tertiary); background: rgba(255,255,255,.2);}
    ul.select-method-payment li .select-payment-type.active:before{border: 2px solid #bbb; opacity: 1 !important;}
    ul.select-method-payment li.credit_card .select-payment-type:before{background: url('~@/assets/images/icons/payment-type-credit-card.svg') no-repeat center #EEE; opacity: .6;}
    ul.select-method-payment li.sepa_swift .select-payment-type:before{background: url('~@/assets/images/icons/payment-type-sepa-swift.svg') no-repeat center #EEE; opacity: .6;}
    ul.select-method-payment li.crypto .select-payment-type:before{background: url('~@/assets/images/icons/payment-type-tether.svg') no-repeat center #EEE; opacity: .6;}
    ul.select-method-payment li.swapix .select-payment-type:before{background: url('~@/assets/images/icons/payment-type-swapix.svg') no-repeat center #EEE; opacity: .6;}
    ul.select-method-payment li.hide{display: none !important;}

    @media (max-width:576px){
        ul.select-method-payment li{width: 50%;}
        ul.select-method-payment li .select-payment-type{font-size: 11px; line-height: 14px; min-height: 38px;}
    }
    
    @media (min-width:576px){
        ul.select-method-payment li{width: 25%;}
        ul.select-method-payment li .select-payment-type{font-size: 12px; line-height: 16px; min-height: 52px;}
    }

    @media (max-width: 1200px){
        ul.select-method-payment{flex-wrap: wrap;}
    }

    @media (min-width: 1200px){
        ul.select-method-payment{flex-wrap: nowrap;}
    }
                        

    /* ul.select-method-payment{display: flex; justify-content: center; width: 100%; margin: 30px 0; padding: 0;}
    ul.select-method-payment li{display: flex; align-items: center;}
    ul.select-method-payment li .select-payment-type{padding: 8px; line-height: 16px; font-size: 12px; text-align: center; border-radius: 12px; background: var(--color-quaternary); color: var(--color-text-secondary); text-transform: uppercase; margin: 5px; cursor: pointer; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; transition: .3s;}
    ul.select-method-payment li .select-payment-type.active{background: var(--color-text-tertiary); color: var(--color-primary);}
    ul.select-method-payment li .select-payment-type.active:before{background-position-y: 0;} */

    .method-pay-animation{margin-bottom: 60px;}
    .method-pay-animation .checks-suapix{padding-bottom: 15px; margin-bottom: 25px; border-bottom: 1px solid rgba(255,255,255,0.3);}
    .method-pay-animation .form-group-check{margin: 0 0 5px 0;}

    .alert-card{border: 2px solid rgba(235, 104, 52, 0.3); background: rgba(235, 104, 52, 0.2); color: #eee; border-radius: 12px; margin-bottom: 20px;}
    @media (max-width: 992px){
        .alert-card{padding: 8px 12px;}
    }

    @media (min-width: 992px){
        .alert-card{padding: 8px 10px 8px 60px; position: relative;}
        .alert-card:before{content: ''; display: block; position: absolute; left: 15px; top: 50%; transform: translateY(-50%); width: 30px; height: 30px; background: url('~@/assets/images/icons/alert.svg') no-repeat; background-size: 30px auto;}
    }

    .buy-payment-cont .error label{color: var(--color-pending);}
    .buy-payment-cont .error .form{border: 1px solid rgba(216,25,25,0.5);}

   
    form{background: var(--color-primary); border-radius: 12px; padding: 16px; margin-bottom: 40px;}
    form label{color: var(--color-text-tertiary);}
    form .form{height: 36px; line-height: 36px;}
    form .expiry-cvv{display: flex; justify-content: space-between; align-items: flex-start;}
    form .expiry-cvv .expiry{width: 50%;}
    form .expiry-cvv .cvv{width: 30%;}
    
    form .obsBuy{margin-top: 40px; padding-top: 30px; border-top: 1px solid rgba(255,255,255,0.2);}
    form .obsBuy h6{font-size: 16px; color: var(--color-text-tertiary); margin: 0 0 8px 0;}
    form .obsBuy p{font-size: 12px; color: var(--color-text-tertiary);}

    .btn-center{text-align: center;}

    /* MODAL CONFIRM */
    .modal-confirm .scroll-vertical{max-height: calc(60vh);}
    .modal-confirm .scroll-vertical h3{font-size: 16px; margin: 15px 0 5px 0;}
    .modal-confirm .scroll-vertical h3:after{display: none;}
    .modal-confirm .confirm-purchase .btn-primary{height: 46px;}
    .modal-confirm .btn-primary.load{background: url('~@/assets/images/icons/loading-secondary.svg') no-repeat center; background-size: auto 100%;}
    .modal-confirm .btn-primary.load:after{display: none;}
    
    /* THREE D SECURITY */
    .modal-confirm iframe{width: 100%; height: calc(100vh - 120px);}

    .modal .cont-modal h2{font-size: 18px; color: var(--color-primary); margin: 0 0 20px 0;}
    .modal .cont-modal h4{font-size: 16px; color: var(--color-primary); margin: 20px 0 5px 0 !important;}
    .modal .cont-modal .scroll-vertical{background: #eee; padding: 8px 10px;}

    .box-error{padding: 10px 16px; margin: 20px 0 0 0;}
    .box-error h3{color: var(--color-waiting) !important;}

    /* MODAL */
    .phase-modal h3 small{display: block; margin-top: 5px;}
    .phase-modal h3:after{display: none;}
    .phase-modal .phase p{display: flex; justify-content: space-between; border-bottom: 1px solid #ddd; padding: 5px; margin-bottom: 0; color: var(--color-primary);}
    .phase-modal .phase p strong{color: var(--color-secondary)}
    .phase-modal .phase p:last-child{border-bottom: none;}
    .phase-modal .btn-whitepaper{margin-top: 30px;}


    @media (min-width: 576px){
        .buy-payment-cont{max-width: 400px; margin: 0 auto;}
    }
</style>

<style>
    .msg-payment-condition{padding: 10px; border-radius: 12px 12px 0 0; margin: 0; box-shadow: none;}
    .msg-payment-condition h3{margin: 0 0 10px 0; color: var(--color-primary); font-size: 16px;}

    .alert-reserve{background: #eee; margin-bottom: 20px; padding: 10px; border-radius: 0 0 12px 12px; border: 5px solid #fff;}
    .alert-reserve p{display: block; font-size: 11px; line-height: 13px; margin-bottom: 10px;}
    .alert-reserve p:last-child{margin-bottom: 0;}
</style>