<template>
    <ion-header class="header">
        <div class="header-row">
            <div class="logo-balances">
                <div class="logo-circle" @click="lnkHome()">
                    <img src="@/assets/images/dne.svg">
                </div>
            </div>
            
            <div class="user">
                <div class="avatar" v-if="showContent">
                    <h4>
                        <!-- <span>{{ getMyLanguage("header", "header.welcome") }}</span> -->
                        {{dataProfile.profile_full_name}}
                        
                        <div class="dne"><i>DNE</i> <span>Pay</span></div>
                    </h4>
                    
                    <div class="photoUser">
                        <img src="@/assets/images/users/user.svg">
                    </div>
                </div>
            </div>

            <div class="selectLanguage">
                <SelectLanguage />
            </div>
        </div>

        <ValidatePhoneNumber />
    </ion-header>
</template>

<script>
    import {IonHeader} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";
    import SelectLanguage from "@/components/SelectLanguage.vue";
    import ValidatePhoneNumber from "@/components/ValidatePhoneNumber.vue";

    export default {
        components: {
            IonHeader,
            SelectLanguage,
            ValidatePhoneNumber
        },
        data () {
            return {
                flagSelected: {},
                showContent: false,
                dataAccount: []
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            lnkHome(){
                window.location.href = this.flagSelected + '/platform/home/'
            }
        },

        async mounted () {
            this.flagUrl();            
            
            this.dataProfile = JSON.parse(localStorage.getItem("profile"))

            this.showContent = true
        }
    }
</script>


<style scoped src="@/assets/css/Header.css" />