<template>
    <ion-app :scroll-events="true" class="security">
        <ion-page>
            <div class="header-step">
                <Header />
            </div>

            <ion-content class="ion-padding security-content">
                <div class="content">
                    <div class="load-blue" v-if="!showContent"></div>
                    
                    <div class="security-cont" v-if="showContent">
                        <h1 class="title-secondary">{{ getMyLanguage("footer", "footer.lnk.security") }}</h1>
                        
                        <h3>ECOSISTEMA DNE ETVE SL “DNE Platform”</h3>
                        <p><strong>Web and App Security Policy:</strong></p>
                        <p>For security purposes and to make sure our website and app “DNE Platform” remains available to all users, we use special software programs for monitoring network traffic to identify unauthorized attempts to upload or change information, or otherwise to cause damage to this government computer system. These programs collect no information that would directly identify individuals, but they do collect information that could help us identify someone attempting to tamper with this website.</p><p>If you use our website or app, you should understand that all activities may be monitored and recorded. Anyone using this system expressly consents to such monitoring.</p><p>WARNING -- If such monitoring reveals possible evidence of criminal activity, monitoring records may be provided to law enforcement officials. Except for authorized law enforcement investigations, we make no other attempts to identify individual users or their usage habits. We only use raw monitoring data logs for determining trends in usage patterns and in diagnosing system problems.</p>
                    </div>
                </div>
            </ion-content>
            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/dneplatform/Header.vue';
    import Footer from '@/components/dneplatform/Footer.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                flagSelected: {},
                showContent: false,
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
        },


        async mounted () {
            this.flagUrl()

            setTimeout(() => {
                this.showContent = true
            }, 2000);
        }
        
        // created () {
            
        // }
    }
</script>


<style scoped>
    /* .ion-page{background: #000;} */
    
    .security-cont{padding: 16px 0;}
    .security-cont .title-secondary{margin-bottom: 30px;}
    .security-cont h3{font-size: 16px; margin-bottom: 20px;}
    
    .load-blue{position: absolute; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); width: 120px; height: 120px; margin: 0;}
</style>