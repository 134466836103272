<template>
    <ion-app :scroll-events="true" class="profile">
        <ion-page>
            <div class="header-step">
                <Header />
            </div>

            <ion-content class="profile">
                <div class="content">
                    <!-- <Step /> -->

                    <div class="load-green load-200" v-if="!showContent"></div>

                    <div class="ion-padding" v-if="showContent">
                        <h1 class="title-secondary">{{ getMyLanguage("profile", "profile.title") }}</h1>

                        <div class="twoColumns">
                            <label>
                                {{ getMyLanguage("profile", "profile.label.firstName") }}
                                <input type="text" class="form" id="firstName" v-model="dataProfile.personName" required>
                            </label>
                            <label>
                                {{ getMyLanguage("profile", "profile.label.lastName") }}
                                <input type="text" class="form" id="lastName" v-model="dataProfile.personSurname" required>
                            </label>
                        </div>

                        <div class="twoColumns-tablet">
                            <label>
                                {{ getMyLanguage("profile", "profile.label.referred") }}
                                <div class="form form-disabled">{{dataProfile.accountReferrerName}}</div>
                            </label>
                            
                            <label>
                                <div class="changePassword" @click="changePassModal()"><span>{{getMyLanguage("profile","profile.btn-change-password")}}</span></div>
                            </label>

                            <!-- <label>
                                {{ getMyLanguage("profile", "profile.label.username") }}
                                <input type="text" class="form" id="username" v-model="dataProfile.personUsername" required>
                            </label> -->
                        </div>

                        <!-- KYC VERIFIED -->
                        <div class="kyc-content" v-if="dataProfile.accountStatus === 'Verified' && dataProfileShow">
                            <label class="lbl-status-verified">{{ getMyLanguage("profile", "profile.label.identification") }}</label>
                            <div class="box kyc-verified">
                                <div class="kyc">
                                    <img src="@/assets/images/icons/kyc-verified.svg">
                                    <div>
                                        <h6>{{ getMyLanguage("profile", "profile.identification.verified.title") }}</h6>
                                        
                                        <div class="expiration-date" v-if="dataProfile.accountStatus === 'Verified'">
                                            <small>{{ getMyLanguage("profile", "profile.account.lbl.identification-expirationDate") }}</small>
                                            <span>25/06/2022</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- KYC PENDING -->
                        <div class="kyc-content" v-if="dataProfile.accountStatus != 'Verified' && dataProfileShow">
                            <label class="lbl-status-pending">{{ getMyLanguage("profile", "profile.label.identification") }}</label>
                            <div class="box kyc-pending">
                                <div class="kyc">
                                    <img src="@/assets/images/icons/kyc-pending.svg">
                                    <div>
                                        <h6>{{ getMyLanguage("profile", "profile.identification.pending.title") }}</h6>
                                        <p>{{ getMyLanguage("profile", "profile.identification.pending.msg") }}</p>
                                        <a href="#" target="_blank" class="btn-secondary">{{ getMyLanguage("profile", "profile.identification.pending.btn") }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label>
                            {{ getMyLanguage("profile", "profile.label.phone") }}
                        </label>
                        <div class="formPhone active">
                            <div class="ddiPhone">
                                <div class="select-ddi" v-if="selectedCountryDDI.changeMask === true">
                                    <div class="open-select-ddi" @click="selectedCountryDDI.openSelect = true"></div>
                                    
                                    <div class="selected-ddi">
                                        <div class="flag">
                                            <img :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + selectedCountryDDI.flag + "/flags"' v-if="selectedCountryDDI.flag != ''">
                                        </div>
                                        <span class="ddi">+{{selectedCountryDDI.ddi}}</span>
                                    </div>
                                </div>

                                <label v-if="selectedCountryDDI.changeMask === true" class="lbl-phone lbl-status-waiting">
                                    <!-- <div class="tooltip-error" v-if="dataProfile.tooltipPhone === true">Telefone vazio</div> -->

                                    <input v-mask="selectedCountryDDI.phoneMask" v-model="dataProfile.personPhoneNumber" type="tel" class="form form-phone" spellcheck="false" autocapitalize="off" :placeholder='getMyLanguage("register", "register.placeholder.phone")'>
                                </label>
                                
                                <div class="load" v-if="selectedCountryDDI.changeMask === false"></div>
                            </div>

                            <div class="validate-phone">
                                <div class="btn-tertiary">
                                    <span>{{ getMyLanguage("profile", "profile.validate.phone.title") }}</span>
                                    <small>{{ getMyLanguage("profile", "profile.validate.phone.msg") }}</small>
                                </div>
                                <div class="sms-code">
                                    <input type="text" class="form" id="sms-code" :placeholder='getMyLanguage("profile", "profile.validate.phone.placeholder")'>
                                    <div class="btn-send-form-arrow"></div>
                                </div>
                            </div>
                        </div>
                        

                        <!-- <label class="lbl-status-waiting">
                            {{ getMyLanguage("profile", "profile.label.phone") }}
                            <input type="text" class="form" id="phone" v-model="dataProfile.personPhoneNumber" required>
                        </label> -->

                        
                        <div class="twoColumns-tablet">
                            <label class="lbl-status-validated">
                                {{ getMyLanguage("profile", "profile.label.email") }}
                                <div class="form form-disabled" v-text="dataProfile.personEmail"></div>
                            </label>
                            <label>
                                {{ getMyLanguage("profile", "profile.label.country") }}
                                <select id="language" class="form" v-model="dataProfile.countryId">
                                    <option selected disabled hidden>{{dataProfile.countryName}}</option>
                                    <option v-for="(itemCountry,index) in dataCountry" :key="index" :value="itemCountry.id">{{itemCountry.name}}</option>
                                </select>
                            </label>
                        </div>

                        <div class="twoColumns">
                            <label>
                                {{ getMyLanguage("profile", "profile.label.language") }}
                                <select id="language" class="form" v-model="dataProfile.languageId">
                                    <option selected disabled hidden>{{dataProfile.languageName}}</option>
                                    <option v-for="(itemLanguage,index) in dataLanguage" :key="index" :value="itemLanguage.id">{{itemLanguage.name}}</option>
                                </select>
                            </label>
                            <label>
                                {{ getMyLanguage("profile", "profile.label.currency") }}
                                <!-- <input type="text" class="form" id="currency" v-model="dataProfile.assetName" required> -->
                                <select id="language" class="form" v-model="dataProfile.assetId">
                                    <option selected disabled hidden>{{dataProfile.assetName}}</option>
                                    <option v-for="(itemAsset,index) in dataAsset" :key="index" :value="itemAsset.id">{{itemAsset.name}}</option>
                                </select>
                            </label>
                        </div>

                        <div class="twoColumns">
                            <label>
                                {{ getMyLanguage("profile", "profile.label.birthDate") }}
                                <input type="text" class="form" id="birthDate" :value="dateTime(dataProfile.personDOB)" required>
                            </label>
                            <label>
                                {{ getMyLanguage("profile", "profile.label.gender") }}
                                <select id="gender" class="form" v-model="dataProfile.personGenderId">
                                    <!-- <option selected disabled hidden>{{ getMyLanguage("client-space", "client-space.account.lbl.gender.option." + dataProfile.personGenderExtra) }}</option> -->
                                    <option selected disabled hidden>{{ getMyLanguage("profile", dataProfile.personGenderExtra) }}</option>
                                    <option v-for="(itemGender,index) in dataGender" :key="index" :value="itemGender.id">{{ getMyLanguage("profile", itemGender.extra) }}</option>
                                </select>
                            </label>
                        </div>

                        <div class="twoColumns">
                            <label>
                                {{ getMyLanguage("profile", "profile.label.taxNumber") }}
                                <input type="text" class="form" id="taxnumber" v-model="dataProfile.personTaxNumber" required>
                            </label>

                            <label>
                                {{ getMyLanguage("profile", "profile.label.indicate") }}
                                <div class="indication" @click="copyUrl()"><span>{{ getMyLanguage("profile", "profile.placeholder.copyMyLink") }}</span></div>

                                <!-- <div class="indication" @click="indication.overlay = true"><span>Luis Santos</span></div>
                                <div class="indication-share" v-if="indication.overlay === true">
                                    
                                    <div class="box box-share">
                                        <div class="close-share" @click="indication.overlay = false"></div>
                                        <div class="share facebook"><span>Facebook</span></div>
                                        <div class="share twitter"><span>Twitter</span></div>
                                        <div class="share whatsapp"><span>WhatsApp</span></div>
                                    </div>

                                    <div class="content-share facebook">

                                    </div>
                                </div> -->

                            </label>
                        </div>

                        <!-- {{dataProfile}} -->

                        <button @click="saveProfile()" type="submit" expand="block" class="btn-primary">{{ getMyLanguage("profile", "profile.btn.save") }}</button>
                    </div>
                </div>
            </ion-content>


            <!-- MODAL CHANGE PASSWORD -->
            <div class="modal modal-change-password" v-if="changePass.modal">
                <div class="modal-cont">
                    <div class="close-modal" @click="changePass.modal = false"></div>
                    <div class="box" v-if="changePass.confirmChange">
                        <div class="change-pass-content">
                            <p><strong>{{getMyLanguage("client-space","client-space.account.modal.really-change-password")}}</strong></p>
                        </div>
                        <div class="buttons">
                            <button @click="changePassword()" v-if="!changePass.load" class="btn-primary">{{getMyLanguage("client-space","client-space.account.modal.btn-yes")}}</button>
                            <button @click="changePass.modal = false" class="btn-cancel-primary">{{getMyLanguage("client-space","client-space.account.modal.btn-no")}}</button>
                        </div>
                    </div>

                    <div class="box" v-if="emailRemember.load">
                        <div class="load load-110"></div>
                    </div>

                    <!-- CHANGE SUCCESS -->
                    <div class="box" v-if="changePass.successChange && !emailRemember.load">
                        <div class="change-pass-content change-success">
                            <p><strong>{{getMyLanguage("client-space","client-space.account.modal.sent-email-instructions-change-password")}}</strong></p>
                            <div class="attention"><p v-html='getMyLanguage("client-space","confirm-email-spam")'></p></div>
                        </div>
                    </div>

                    <!-- CHANGE ERROR -->
                    <div class="box" v-if="changePass.errorChange && !emailRemember.load">
                        <div class="change-pass-content change-error">
                            <div class="box" v-html='getMyLanguage("client-space","client-space.account.modal.changePass-error")'></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overlayModal" v-if="changePass.modal"></div>


            <ul class="list-ddi" v-if="selectedCountryDDI.openSelect === true">
                <li v-for="(ddiList,index) in dataCountry" :key="index" @click="(dataProfile.personPhoneCountryId = ddiList.id), (selectedCountryDDI.phoneMask = ddiList.phoneMask), (this.changeMask()), (selectedCountryDDI.ddi = ddiList.ddi), (selectedCountryDDI.flag = ddiList.flag), (selectedCountryDDI.openSelect = false)">
                    <div class="flag">
                    <img class="anime" :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + ddiList.flag + "/flags"'>
                    </div>
                    <span class="name-country">{{ddiList.name}}</span>
                    <span class="ddi">(+{{ddiList.ddi}})</span>
                </li>
            </ul>

            <div class="overlay-list-ddi" v-if="selectedCountryDDI.openSelect === true">
                <div class="close-select-ddi" @click="selectedCountryDDI.openSelect = false"></div>
            </div>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import moment from 'moment';

    import api from '@/config/apiPlatform.js';

    import {mask} from 'vue-the-mask'

    import Header from '@/components/dneplatform/Header.vue';
    // import Step from '@/components/dneplatform/Step.vue';
    import Footer from '@/components/dneplatform/Footer.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            Header,
            // Step,
            Footer
        },
        directives: {mask},
        data () {
            return {
                showContent: false,
                flagSelected: {},
                dataProfile: "",
                dataCountry: [],
                dataLanguage: [],
                dataAsset: [],
                dataGender: [],
                dataProfileShow: false,
                indication: {
                    overlay: false
                },
                selectedCountryDDI: {
                    changeMask: true,
                    phoneMask: '',
                    countrySelected: false,
                    flag: ''
                },

                emailRemember: {
                    load: false
                },

                changePass: {
                    modal: false,
                    email: '',
                    confirmChange: true,
                    successChange: false
                }
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
            dateTime(value) {
                // return moment(value).locale(this.flagSelected).format('DD/MM/YYYY');
                return moment(value).format('DD/MM/YYYY');
            },

            changePassModal(){
                this.changePass.email = this.dataProfile.personEmail
                this.changePass.modal = true
                this.changePass.confirmChange = true
                this.changePass.successChange = false
            },

            changePassword(){
                this.changePass.confirmChange = false
                this.emailRemember.load = true
                
                api.post("/api/v1/platform/account/password/reset", this.emailRemember)
                .then(response => {
                    this.registerId = response.data.id;
                    
                    setTimeout(() => {
                        this.emailRemember.load = false
                        this.changePass.successChange = true
                    }, 500);
                })
                .catch(error => {
                    this.emailRemember.load = false
                    this.changePass.errorChange = true

                    console.error("There was an error!", JSON.stringify(error));
                })
            },

            changeMask(){
                this.selectedCountryDDI.changeMask = false

                setTimeout(() => {
                    const maskPhone = this.selectedCountryDDI.phoneMask
                    
                    this.selectedCountryDDI.phoneMask = maskPhone.replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#")

                    this.selectedCountryDDI.changeMask = true
                }, 1000);
            },

            saveProfile(){
                const update = this.dataProfile;
                api.put("/api/v1/platform/account", update)
                .then(response => {
                    this.registerId = response.data.id;
                    
                    window.location.href = '/' + this.flagSelected + '/platform/profile-success'
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    
                    this.$toast.error(this.errorMessage);

                    setTimeout(this.$toast.clear, 3000)
                });
            },

            copyUrl(){
                // pendente gerar o link e copiar

                this.openToast()
            },

            async openToast() {
                const toast = await toastController
                    .create({
                    message: this.getMyLanguage("profile", "profile.copied.link"),
                    color: "success",
                    duration: 2000
                    })
                return toast.present();
            },
        },

        async mounted () {
            this.flagUrl()

            this.dataProfile = JSON.parse(localStorage.getItem("profile"))
            
            const resumeClientCountry = await api.get('/api/v1/platform/country')
            this.dataCountry = resumeClientCountry.data
            this.dataCountryAccount = resumeClientCountry.data.filter(item => item.id === this.dataProfile.personPhoneCountryId)[0]
            // console.log(this.dataCountry)
            // console.log(this.dataCountryAccount)

            const resumeClientLanguage = await api.get('/api/v1/platform/language')
            this.dataLanguage = resumeClientLanguage.data
            
            const resumeClientAsset = await api.get('/api/v1/pay/asset/type/fiat')
            this.dataAsset = resumeClientAsset.data

            const resumeClientGender = await api.get('/api/v1/public/attributes/codes/person_gender')
            this.dataGender = resumeClientGender.data
            
            setTimeout(() => {
                this.selectedCountryDDI.phoneMask = this.dataCountryAccount.phoneMask
                this.selectedCountryDDI.flag = this.dataCountryAccount.flag
                this.selectedCountryDDI.ddi = this.dataCountryAccount.ddi

                this.showContent = true
            }, 100);
            
            this.dataProfileShow = true
        }
        
        // created () {
            
        // }
    }
</script>


<style scoped>
    .twoColumns{display: flex; align-items: flex-end;}
    .twoColumns label{width: 50%;}
    .twoColumns label:nth-of-type(1){padding-right: 5px;}
    .twoColumns label:nth-of-type(2){padding-left: 5px;}

    .kyc-content{margin-top: 30px;}
    .box .kyc{display: flex;  align-items: center;}
    .box .kyc img{width: auto; height: 82px; margin-right: 20px;}
    .box .kyc div h6{font-size: 20px; font-family: var(--font-primary-semibold); margin: 0 0 5px;}
    .box .kyc div p{font-size: 11px; color: var(--color-primary); margin: 0;}
    .box .kyc div .btn-secondary{margin: 10px 0 0 0;}

    .box.kyc-verified .kyc div h6{color: var(--color-secondary);}
    .box.kyc-pending .kyc div h6{color: var(--color-pending);}

    .btn-tertiary{margin: 0 0 15px 20px;}

    .validate-phone .btn-tertiary{white-space: nowrap;}
    .validate-phone .sms-code{display: none; /*display: flex;*/}
    .validate-phone .sms-code #sms-code{width: calc(100% - 36px); border-radius: 12px 0 0 12px;}

    form .form{height: 40px; line-height: 40px; padding: 0 10px; background: var(--color-text-tertiary);}
    form label{position: relative; width: 100%; display: block;}
    form label:after{display: block; width: 16px; height: 16px; border-radius: 50%; position: absolute; z-index: 20; top: 12px; right: 0; background-size: 8px auto !important;}
    form .kyc label:after{top: auto; bottom: -8px;}
    .lbl-status-validated:after, .lbl-status-verified:after{content: ''; background: url('~@/assets/images/icons/lbl-status-validated.svg') no-repeat center var(--color-secondary);}
    .lbl-status-pending:after{content: ''; background: url('~@/assets/images/icons/lbl-status-pending.svg') no-repeat center var(--color-pending);}
    .lbl-status-waiting:after{content: ''; background: url('~@/assets/images/icons/step-status-waiting.svg') no-repeat center var(--color-waiting);}

    .btn-primary{margin: 20px 0 30px 0;}

    .expiration-date{display: flex; justify-content: center; flex-direction: column; padding: 8px 10px; border-radius: 8px; background: #f5f5f5;}
    .expiration-date small{display: block; font-size: 11px; line-height: 13px; color: #555; margin-bottom: 5px;}
    .expiration-date span{display: block; font-size: 12px; line-height: 15px;}


    /* SELECT DDI */
    .formPhone.active{display: flex;}
    .formPhone.active .ddiPhone{display: flex; align-items: flex-start;}
    .formPhone.active .ddiPhone .form.form-phone{background: #f7f7f7 !important; padding: 0 10px !important; border-radius: 0 12px 12px 0;}
    .formPhone.active .ddiPhone .form.form-phone:focus{-webkit-box-shadow: none !important; -moz-box-shadow: none !important; box-shadow: none !important;}
    
    .select-ddi{display: inline-flex; position: relative; margin-bottom: 10px; height: 40px;}
    .select-ddi .selected-ddi{display: flex; align-items: center; flex-direction: row; padding-left: 10px; background: #f7f7f7; border-radius: 12px 0 0 12px;}
    .select-ddi .selected-ddi .flag{margin-right: 5px; width: 24px; height: auto; position: relative;}
    .select-ddi .selected-ddi .flag img{display: block; width: 100%; height: auto;}
    .select-ddi .selected-ddi .flag:after{content: ''; display: block; width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid #888888; position: absolute; left: 0; bottom: -8px;}
    .select-ddi .selected-ddi .ddi{display: block; font-size: 14px; color: var(--color-text-secondary)}
    .lbl-phone{width: 100%;}
    .lbl-phone:after{top: -10px;}

    .select-ddi .open-select-ddi{position: absolute; z-index: 20; left: 0; top: 0; width: 100%; height: 100%; cursor: pointer;}
    .list-ddi{position: absolute; z-index: 999; left: 50%; top: 50%; max-width: 90%; max-height: 90%;
    overflow-y: auto;  transform: translateX(-50%) translateY(-50%); background: var(--color-text-tertiary); padding: 10px 20px; margin: 0; border-radius: 12px; -webkit-animation: openFlagsLogin .4s alternate;}
    .list-ddi li{display: inline-flex; align-items: center; border-bottom: 1px solid var(--color-tertiary); line-height: 32px; cursor: pointer;}
    .list-ddi li:last-child{border-bottom: none;}
    .list-ddi li .flag{width: 25px; margin-right: 10px;}
    .list-ddi li .flag img{display: block; width: 100%; height: auto;}
    .list-ddi li .name-country{font-size: 14px;}
    .list-ddi li .ddi{margin-left: 5px; font-size: 12px;}

    .overlay-list-ddi{position: fixed; z-index: 990; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.7)}
    .overlay-list-ddi .close-select-ddi{position: absolute; top: 10px; right: 10px; width: 40px; height: 40px; background: url('~@/assets/images/icons/close-white.svg') no-repeat center; background-size: 26px auto;}

    .load{width: 40px; height: 40px; margin: 0 auto 25px; background: url('~@/assets/images/icons/loading-secondary.svg') no-repeat center; background-size: 100% auto;}
    .load.load-110{width: 80px; height: 80px; margin: 30px auto;}

    .select-ddi .load{width: 40px; height: 40px; margin: 0 0 14px 0;}


    /* SHARE */
    .indication{display: flex; justify-content: space-between; align-items: center; margin-bottom: 15px; background: #ccc; border-radius: 12px;}
    .indication span{display: block; font-size: 14px; line-height: 18px; color: var(--color-text-secondary); padding: 0 15px;}
    
    .indication:after{content: ''; display: block; width: 30px; height: 40px; background: url('~@/assets/images/icons/copy.svg') no-repeat center left; background-size: 16px auto;}
    /* .indication:after{content: ''; display: block; width: 30px; height: 40px; background: url('~@/assets/images/icons/share.svg') no-repeat center left; background-size: 16px auto;} */

    .indication-share{position: fixed; z-index: 9999; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.45)}
    
    .box-share{position: absolute; z-index: 99; top: 50%; left: 50%; transform: translateY(-50%) translateX(-50%); margin: 0; -webkit-animation: openShare .4s alternate;}
    
    .box-share .close-share{position: absolute; z-index: 20; right: -40px; top: 0; width: 32px; height: 32px; background: url('~@/assets/images/icons/close-white.svg') no-repeat center; background-size: 70%;}
    
    .box-share .share{display: flex; margin-bottom: 5px; border-radius: 6px;}
    .box-share .share:before{content: ''; display: block; width: 30px; height: 30px; background-size: 24px auto !important;}
    .box-share .share span{display: block; font-size: 12px; line-height: 30px; color: var(--color-text-tertiary); padding: 0 10px;}

     .box-share .share.facebook{background: #3B5998;}
     .box-share .share.facebook:before{background: url('~@/assets/images/icons/facebook.svg') no-repeat center rgba(0,0,0,0.2);}
     
     .box-share .share.twitter{background: #00ACEE;}
     .box-share .share.twitter:before{background: url('~@/assets/images/icons/twitter.svg') no-repeat center rgba(0,0,0,0.2);}
     
     .box-share .share.whatsapp{background: #25D366;}
     .box-share .share.whatsapp:before{background: url('~@/assets/images/icons/whatsapp.svg') no-repeat center rgba(0,0,0,0.2);}
    
    @keyframes openShare{
        0% {margin-top: 30px; opacity: 0;}
        100% {margin-top: 0; opacity: 1;}
    }

    @media (max-width: 340px){
        form .form{font-size: 13px;}
    }

    @media (min-width: 576px){
        .twoColumns-tablet{display: flex; align-items: flex-end;}
        .twoColumns-tablet label{width: 50%;}
        .twoColumns-tablet label:nth-of-type(1){padding-right: 5px;}
        .twoColumns-tablet label:nth-of-type(2){padding-left: 5px;}

        .formPhone.active .ddiPhone{width: 50%; padding-right: 5px;}
    }


    .changePassword{width: 100%; height: 40px; margin-bottom: 15px; display: flex; align-items: center; background: #ccc; border-radius: 12px; overflow: hidden; cursor: pointer;}
    .changePassword span{display: block; font-size: 14px; line-height: 18px; color: var(--color-primary); padding: 0 15px;}
    .changePassword:before{content: ''; display: block; width: 40px; height: 40px; background: url('~@/assets/images/icons/change-password.svg') no-repeat center rgba(255,255,255,0.2); background-size: 55% auto;}

    @media (max-width: 576px){
        .changePassword{margin-top: 30px;}
    }
    

    /* CHANGE PASS */
    .modal-change-password .modal-cont{width: 100%;}
    .modal-change-password .modal-cont .change-pass-content{padding: 15px;}
    .modal-change-password .modal-cont .change-pass-content p{text-align: center; font-size: 14px;}
    .modal-change-password .modal-cont .buttons{display: flex; justify-content: space-between; width: 100%;}
    .modal-change-password .modal-cont .buttons button{width: 50%; padding: 0; margin: 0 3px;}
    .modal-change-password .modal-cont .buttons button:after{display: none;}

    .modal-change-password .modal-cont .change-success{background: url('~@/assets/images/bg-green.png') no-repeat center; background-size: cover; border-radius: 12px; padding: 14px; color: var(--color-text-tertiary); text-align: center;}
    .modal-change-password .modal-cont .change-success p{color: var(--color-text-tertiary); margin: 0;}
    .modal-change-password .modal-cont .change-success .attention{margin-top: 20px; padding: 5px; border-radius: 5px; background: #ddd;}
    .modal-change-password .modal-cont .change-success .attention p{font-size: 12px; line-height: 17px; color: #555;}
    .modal-change-password .modal-cont .change-error{background: url('~@/assets/images/bg-red.png') no-repeat center; background-size: cover; border-radius: 12px; padding: 20px 20px 10px 20px; color: var(--color-text-tertiary); text-align: center;}
</style>