<template>
    <ion-app :scroll-events="true" class="pending">
        <ion-page>
            <div class="header-step">
                <Header />
            </div>

            <ion-content class="pending-content">
                <div class="content">
                    <!-- <Step /> -->
                    <div class="load-blue" v-if="!showContent"></div>

                    <!-- BOX ERROR -->
                    <div class="container ion-padding" v-if="showContent && errorsGet.dataPending != false">
                        <!-- {{errorsGet.dataPending}} -->
                        <div class="box-error">
                            <h3 class="title-primary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                            <p>{{ getMyLanguage("box-error", errorsGet.dataPending) }}</p>
                            <div class="cod-error">
                                Cód.: {{errorsGet.dataPending}}
                            </div>
                        </div>
                    </div>

                    <!-- PENDING CONTENT -->
                    <div class="container ion-padding" v-if="showContent">                
                        <h2 class="title-primary">{{getMyLanguage("kyc","pending.title")}}</h2>
                        
                        <div class="box-pending">
                            <p>{{getMyLanguage("kyc","pending.msg")}}</p>
                        </div>
                    </div>
                </div>
            </ion-content>
            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/dneinternational/Header.vue';
    // import Step from '@/components/dneplatform/Step.vue';
    import Footer from '@/components/dneinternational/Footer.vue';

    import api from '@/config/apiPlatform.js';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            Header,
            // Step,
            Footer
        },
        data () {
            return {
                flagSelected: {},
                showContent: false, 
                dataPending: {},
                errorsGet: {
                    dataPending: false
                }
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
        },


        async mounted () {
            this.flagUrl()

            await api.get('/api/v1/platform/account/international/kyc')
            .then(response => {
                this.dataPending = response.data

                this.showContent = true
            })
            .catch(error => {
                console.log(error.response.status)
                this.errorsGet.dataPending = error.response.status
                this.showContent = true
            })
        }
        
        // created () {
            
        // }
    }
</script>


<style scoped>
    .box-pending{max-width: 500px; margin: 50px auto; background: url('~@/assets/images/bg-pending.svg') no-repeat; background-size: cover; border-radius: 12px !important; padding: 30px 16px; text-align: center;}
    .box-pending p{color: #fff;}
    .box-pending p:last-child{margin-bottom: 0;}
</style>