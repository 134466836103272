<template>
    <ion-app :scroll-events="true">
        <div class="sign-register">
            <div class="logo">
                <img src="@/assets/images/logo-dne.svg" >
            </div>

            <ion-page>
                <ion-content>
                    <div class="content">
                        <div class="content-register">
                            <div class="bts-login-register">
                                <div class="lnk-tab" @click="signIn()">{{ getMyLanguage("login", "login.nav.signIn") }}</div>
                                <div class="lnk-tab active">{{ getMyLanguage("login", "login.nav.createAccount") }}</div>
                                
                                <!-- <router-link :to="{ name: 'Login' }" class="lnk-tab">Sign in</router-link>
                                <router-link :to="{ name: 'Register' }" class="lnk-tab active">Create an account</router-link> -->
                            </div>

                            <div class="form-register ion-padding">
                                <ion-row>
                                    <ion-col class="header">
                                        <h3>{{ getMyLanguage("register", "register.register.title") }}</h3>
                                    </ion-col>
                                    <ion-col size="auto" class="selectLang">
                                        <SelectLanguage />
                                    </ion-col>
                                </ion-row>

                                <form>
                                    <ion-list>
                                        <ion-item lines="none">
                                            <!-- <ion-label class="lbl-floating">{{ getMyLanguage("register", "register.placeholder.fullName") }}</ion-label> -->
                                            <div class="tooltip-error" v-if="newUser.tooltipName === true">Name error</div>
                                            <input v-model="newUser.name" type="text" class="form form-fullname" spellcheck="false" autocapitalize="off" :placeholder='getMyLanguage("register", "register.placeholder.fullName")' v-on:blur="newUser.tooltipName = false">
                                        </ion-item>

                                        <ion-item lines="none">
                                            <!-- <ion-label class="lbl-floating">{{ getMyLanguage("register", "register.placeholder.yourEmail") }}</ion-label> -->
                                            <div class="tooltip-error" v-if="newUser.tooltipEmailValidate === true">E-mail inválido</div>
                                            <input v-model="newUser.email" type="email" class="form form-email" spellcheck="false" autocapitalize="off" :placeholder='getMyLanguage("register", "register.placeholder.yourEmail")' v-on:blur="newUser.tooltipEmailValidate = false">
                                        </ion-item>

                                        <ion-item lines="none" class="autocomplete formResidenceCountry">
                                            <!-- <ion-label class="lbl-floating">{{ getMyLanguage("register", "register.placeholder.residenceCountry") }}</ion-label> -->
                                            <div class="tooltip-error" v-if="newUser.tooltipCountryId === true">Selecione o país</div>
                                            <input type="text" id="residenceCountry" class="form form-country" v-on:click="dropListCountry(true)" v-on:keyup="filterList" v-model="selectedCountry.name" spellcheck="false" autocomplete="off" :placeholder='getMyLanguage("register", "register.placeholder.residenceCountry")' v-on:blur="newUser.tooltipCountryId = false">
                                            
                                            <div class="closeAutocomplete closeDropListCountry" v-if="listCountryStatus === true" v-on:click="dropListCountry(false)"></div>
                                            <ul class="box" id="drop-list" v-if="listCountryStatus === true">
                                                <li v-for="(itemList,index) in dataCountry" :key="index" v-on:click="(newUser.countryId = itemList.id), (newUser.PhoneCountryId = itemList.id), (this.changeMask()), (selectedCountryDDI.countrySelected = true), (selectedCountryDDI.active = 'active'), (selectedCountryDDI.phoneMask = itemList.phoneMask), (selectedCountryDDI.ddi = itemList.ddi), (selectedCountryDDI.flag = itemList.flag), (selectedCountry.name = itemList.name), (dropListCountry(false))"><a>{{itemList.name}}</a></li>
                                                <!-- <li v-for="(itemList,index) in dataCountry" :key="index" v-on:click="(newUser.countryId = itemList.id), (selectedCountry.name = itemList.name), (dropListCountry(false))"><a>{{itemList.name}}</a></li> -->
                                            </ul>
                                        </ion-item>

                                        <!-- {{ selectedCountryDDI }} -->

                                        <!-- {{ newUser }} -->

                                        <div class="formPhone" :class="selectedCountryDDI.active">
                                            <div class="select-ddi" v-if="selectedCountryDDI.countrySelected === true && selectedCountryDDI.changeMask === true">
                                                <div class="open-select-ddi" @click="selectedCountryDDI.openSelect = true"></div>
                                                
                                                <div class="selected-ddi">
                                                    <div class="flag">
                                                        <img :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + selectedCountryDDI.flag + "/flags"' v-if="selectedCountryDDI.flag != ''">
                                                    </div>
                                                    <span class="ddi">+{{selectedCountryDDI.ddi}}</span>
                                                </div>
                                            </div>

                                            <label v-if="selectedCountryDDI.changeMask === true" class="lbl-phone">
                                                <div class="tooltip-error" v-if="newUser.tooltipPhone === true">Telefone vazio</div>

                                                <input v-mask="selectedCountryDDI.phoneMask" v-model="newUser.phoneNumber" type="tel" class="form form-phone" spellcheck="false" autocapitalize="off" :placeholder='getMyLanguage("register", "register.placeholder.phone")' v-on:blur="newUser.tooltipPhone = false">
                                            </label>
                                            
                                            <div class="load" v-if="selectedCountryDDI.changeMask === false"></div>
                                        </div>

                                        <div class="form-group-check">
                                            <input type="checkbox" name="" id="acceptTerms" class="form-check">
                                            <label for="acceptTerms" class="form-check-label"><small>{{ getMyLanguage("register", "register.accept-terms") }}</small></label>
                                        </div>

                                        <div class="form-group-check">
                                            <input type="checkbox" name="" id="acceptoNotify" class="form-check">
                                            <label for="acceptoNotify" class="form-check-label"><small>{{ getMyLanguage("register", "register.accept-notify") }}</small></label>
                                        </div>


                                        <ion-item lines="none">
                                            <ion-button @click="validateFormRegister()" expand="block" id="btnRegister" class="btnPrimary" v-if="stepRegister.load === false">{{ getMyLanguage("register", "activate.btn.save") }}</ion-button>
                                            <div class="btn-primary btn-loading" v-if="stepRegister.load === true"></div>
                                        </ion-item>
                                    </ion-list>

                                    <!-- <div class="msgError">
                                        <span class="nameError">{{ getMyLanguage("errors", "errors.nameError") }}</span>
                                        <span class="emailError">{{ getMyLanguage("errors", "errors.emailError") }}</span>
                                        <span class="residenceError">{{ getMyLanguage("errors", "errors.residenceError") }}</span>
                                        <span class="phoneError">{{ getMyLanguage("errors", "errors.phoneError") }}</span>
                                    </div> -->
                                </form>
                            </div>
                        </div>
                    </div>
                </ion-content>

                <ul class="list-ddi" v-if="selectedCountryDDI.openSelect === true">
                    <li v-for="(ddiList,index) in dataCountry" :key="index" @click="(newUser.PhoneCountryId = ddiList.id), (selectedCountryDDI.phoneMask = ddiList.phoneMask), (this.changeMask()), (selectedCountryDDI.ddi = ddiList.ddi), (selectedCountryDDI.flag = ddiList.flag), (selectedCountryDDI.openSelect = false)">
                        <div class="flag">
                        <img class="anime" :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + ddiList.flag + "/flags"'>
                        </div>
                        <span class="name-country">{{ddiList.name}}</span>
                        <span class="ddi">(+{{ddiList.ddi}})</span>
                    </li>
                </ul>

                <div class="overlay-list-ddi" v-if="selectedCountryDDI.openSelect === true">
                    <div class="close-select-ddi" @click="selectedCountryDDI.openSelect = false"></div>
                </div>
            </ion-page>
        </div>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, IonRow, IonItem, IonCol, IonList, IonButton} from '@ionic/vue';

    import SelectLanguage from '@/components/SelectLanguage.vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import api from '@/config/apiPlatform.js'
    
    import {mask} from 'vue-the-mask'

    export default {
        components: {
            IonApp,IonPage, IonContent, IonRow, IonItem, IonCol, IonList, IonButton,
            SelectLanguage,
        },
        directives: {mask},
        data () {
            return {
                flagSelected: {},
                dataCountry: [],
                dataLanguage: [],
                dataTranslation: {},
                listCountryStatus: false,
                selectedCountry: {},
                stepRegister: {'load': false,},
                selectedCountryDDI: {
                    changeMask: true,
                    phoneMask: '',
                    countrySelected: false,
                    flag: ''
                },
                newUser: {}
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect

                console.log("idioma: " + flagSelect)
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            signIn(){
                window.location.href = '/' + this.flagSelected + '/login'
            },

            changeMask(){
                this.selectedCountryDDI.changeMask = false

                setTimeout(() => {
                    const maskPhone = this.selectedCountryDDI.phoneMask
                    
                    this.selectedCountryDDI.phoneMask = maskPhone.replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#")

                    this.selectedCountryDDI.changeMask = true
                }, 1000);
            },

            dropListCountry(status){
                this.listCountryStatus = status
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("residenceCountry");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },


            referrerId(){
                const idRef = window.location.href.split("referrer=");
                console.log(idRef)

                if ( idRef[1] != undefined ){
                    this.newUser.referrerId = idRef[1]
                } else{
                    this.newUser.referrerId = '00000000-0000-0000-0000-000000000000'
                }
            },


            validateFormRegister(){
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if ( this.newUser.name === undefined || this.newUser.name === "" ){
                    this.newUser.tooltipName = true
                } else{
                    this.newUser.tooltipName = false
                }

                if ( this.newUser.email === undefined || this.newUser.email === "" ){
                    this.newUser.tooltipEmailValidate = true
                } else{
                    this.newUser.tooltipEmailValidate = false
                }

                if (regex.test(this.newUser.email)) {
                    this.newUser.tooltipEmailValidate = false
                } else {
                    this.newUser.tooltipEmailValidate = true
                }

                if ( this.newUser.countryId === undefined || this.newUser.countryId === "" ){
                    this.newUser.tooltipCountryId = true
                } else{
                    this.newUser.tooltipCountryId = false
                }
                
                if ( this.newUser.phoneNumber === undefined || this.newUser.phoneNumber === "" ){
                    this.newUser.tooltipPhone = true
                } else{
                    this.newUser.tooltipPhone = false
                }

                if ( this.newUser.tooltipName === false && this.newUser.tooltipEmailValidate === false && this.newUser.tooltipCountryId === false && this.newUser.tooltipPhone === false ){
                    this.stepRegister.load = true

                    setTimeout(() => {
                        this.register()
                    }, 1500);
                }


                // if ( this.newUser.name === undefined || this.newUser.name === "" ){
                //     this.newUser.tooltipName = true
                
                // } else if ( this.newUser.email === undefined || this.newUser.email === "" ){
                //     this.newUser.tooltipName = false
                //     this.newUser.tooltipEmail = true
                    
                // } else if (regex.test(this.newUser.email)) {
                //     this.newUser.tooltipEmailValidate = false
                //     this.newUser.tooltipName = false
                //     this.newUser.tooltipEmail = false

                // } else if ( this.newUser.tooltipEmailValidate != false ) {
                //     this.newUser.tooltipEmailValidate = true
                //     this.newUser.tooltipName = false
                //     this.newUser.tooltipEmail = false

                // } else if ( this.selectedCountry.name === undefined || this.selectedCountry.name === "" ){
                //     window.alert('aaa')
                //     this.newUser.tooltipName = false
                //     this.newUser.tooltipEmail = false

                //     this.newUser.tooltipCountryId = true
                // }

                // window.location.href =  this.flagSelected + '/home'

                // this.register()
            },

            // validateEmail() {
            //     const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            //     if (regex.test(this.newUser.email)) {
            //         this.newUser.tooltipEmailValidate = false
            //     } else {
            //         this.newUser.tooltipEmailValidate = true
            //     }
            // },

            register(){
            const register = this.newUser;

            api.post("/api/v1/platform/account", register)
               .then(response => {
                  this.registerId = response.data.id;

                  const languageRedirect = String(window.location.pathname).split("/")[1];
                  window.location.href = '/' + languageRedirect + '/confirm-email/'
                  // window.location.href = '/' + languageRedirect + '/confirm-email/' + response.data.id
               })
               .catch(error => {
                  this.errorMessage = error.message;
                  console.error("There was an error!", error);
                  console.log(error.response.data)

                  this.$toast.error(error.response.data);
                  // this.$toast.show(`Hey! I'm here`);
                  // this.$toast.success(`Hey! I'm here`);
                  // this.$toast.warning(`Hey! I'm here`);
                  // this.$toast.info(`Hey! I'm here`);

                  setTimeout(this.$toast.clear, 5000)
               })
            }
        },


        async mounted () {
            const resumeLanguage = await api.get('api/v1/platform/language')
            this.flagUrl();
            this.dataLanguage = resumeLanguage.data.filter(lang => lang.code === this.flagSelected)[0]
            this.newUser.languageId = this.dataLanguage.id
            
            const resumeClientCountry = await api.get('api/v1/platform/country')
            this.dataCountry = resumeClientCountry.data

            setTimeout(() => {
                this.referrerId()
            }, 3000);
            
            // this.transPurchaseTokenString();
            // this.phasePrivateString()
        }
        
        // created () {
            
        // }
    }
</script>


<style scoped>
    .selectLang{position: initial;}
    ion-content{--background: var(--color-none); height: 100%;}
    .two-bts{display: flex; justify-content: space-between; align-items: center;}
    /* .btn-primary{margin-left: 30px;} */
    .sign-register{background: url('~@/assets/images/banner-login-register.jpg') no-repeat top center; background-size: 100% auto; height: 100%;}

    .logo{background: url('~@/assets/images/bg-logo.png') no-repeat; background-size: 100% auto; width: 140px; padding: 12px 26px 12px 16px; margin-top: 40px;}
    .logo img{display: block;}

    .content-register{position: absolute; left: 0; bottom: 0; width: 100%;}
    /* .content-login ion-col{padding: 0; padding-inline-start: 0; padding-inline-end: 0; height: 40px; display: block;} */
    .bts-login-register{display: flex; justify-content: center;}
    .lnk-tab{display: block; width: 100%; font-size: 14px; line-height: 40px; text-align: center; color: #ffffff; text-decoration: none; font-weight: 600;}
    .lnk-tab.active{background: #DDDDDD; color: var(--color-primary); border-top-left-radius: 20px;}

    @media (min-width: 576px){
        .lnk-tab.active{border-radius: 20px 20px 0 0;}
    }

    /* .form.form-phone{margin-bottom: 20px !important;} */
    .form.form-phone:focus{-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;}


    .formPhone.active{display: flex; align-items: flex-start;}
    .formPhone.active .form.form-phone{background: #f7f7f7 !important; padding: 0 10px 0 5px !important; border-radius: 0 12px 12px 0;}
    .formPhone.active .form.form-phone:focus{-webkit-box-shadow: none !important; -moz-box-shadow: none !important; box-shadow: none !important;}
    
    .select-ddi{display: inline-flex; position: relative; margin-bottom: 10px; height: 40px;}
    .select-ddi .selected-ddi{display: flex; align-items: center; flex-direction: row; padding-left: 50px; background: url('~@/assets/images/icons/form-register-phone.svg') no-repeat 15px center #f7f7f7; background-size: 20px auto; border-radius: 12px 0 0 12px;}
    .select-ddi .selected-ddi .flag{margin-right: 5px; width: 24px; height: auto; position: relative;}
    .select-ddi .selected-ddi .flag img{display: block; width: 100%; height: auto;}
    .select-ddi .selected-ddi .flag:after{content: ''; display: block; width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid #888888; position: absolute; left: 0; bottom: -8px;}
    .select-ddi .selected-ddi .ddi{display: block; font-size: 14px; color: var(--color-text-secondary)}
    .lbl-phone{width: 100%;}

    .select-ddi .open-select-ddi{position: absolute; z-index: 20; left: 0; top: 0; width: 100%; height: 100%; cursor: pointer;}
    .list-ddi{position: absolute; z-index: 999; left: 50%; top: 50%; max-width: 90%; max-height: 90%;
    overflow-y: auto;  transform: translateX(-50%) translateY(-50%); background: var(--color-text-tertiary); padding: 10px 20px; margin: 0; border-radius: 12px; -webkit-animation: openFlagsLogin .4s alternate;}
    .list-ddi li{display: inline-flex; align-items: center; border-bottom: 1px solid var(--color-tertiary); line-height: 32px; cursor: pointer;}
    .list-ddi li:last-child{border-bottom: none;}
    .list-ddi li .flag{width: 25px; margin-right: 10px;}
    .list-ddi li .flag img{display: block; width: 100%; height: auto;}
    .list-ddi li .name-country{font-size: 14px;}
    .list-ddi li .ddi{margin-left: 5px; font-size: 12px;}

    .overlay-list-ddi{position: fixed; z-index: 990; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.7)}
    .overlay-list-ddi .close-select-ddi{position: absolute; top: 10px; right: 10px; width: 40px; height: 40px; background: url('~@/assets/images/icons/close-white.svg') no-repeat center; background-size: 26px auto;}

    .load{width: 40px; height: 40px; margin: 0 auto 25px; background: url('~@/assets/images/icons/loading-secondary.svg') no-repeat center; background-size: 100% auto;}

    .select-ddi .load{width: 40px; height: 40px; margin: 0 0 14px 0;}

    .form-group-check{margin-bottom: 15px;}
    .form-group-check label{display: block; height: 30px;}
    .form-group-check label small{display: flex; align-items: center; height: 30px; line-height: 14px; white-space: initial;}
    .form-group-check label:before{top: 4px;}

    ion-button.btnPrimary{margin-top: 15px;}

    @keyframes openFlagsLogin{
        0% {margin-top: 30px; opacity: 0;}
        100% {margin-top: 0; opacity: 1;}
    }
</style>