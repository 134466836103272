<template>
    <ion-app :scroll-events="true" class="token">
        <ion-page>
            <div class="header-step">
                <Header />
            </div>

            <ion-content class="itoken-content">
                <div class="content">
                    <!-- <Step /> -->

                    <div class="ion-padding">
                        <h1 class="title-primary">DNE <span>T</span>oken</h1>

                        <!-- ERROR -->
                        <div class="error">
                            <div class="box-error-card">
                                <img src="~@/assets/images/icons/alert.svg">
                                <h3 v-html='getMyLanguage("error-purchase",msgError)'></h3>
                            </div>
                        </div>

                        <!-- ACTION -->
                        <div class="action">
                            <a :href=' flagSelected + "/international/token"' class="btn-another-method">
                                <span v-html='getMyLanguage("error-purchase-action", "another-method")'></span>
                            </a>
                        </div>
                    </div>
                </div>
            </ion-content>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";
    
    import Header from '@/components/dneinternational/Header.vue';
    import Footer from '@/components/dneinternational/Footer.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                flagSelected: "",
                msgError: "",
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
            
            purchaseError(){
                const urlParams = new URLSearchParams(window.location.search);
                const errorPurchase = urlParams.get('error');
                console.log(errorPurchase)

                if ( errorPurchase === "third_party_stripe_create_account_failure" || errorPurchase === "third_party_stripe_create_payment_intent_failure" || errorPurchase === "third_party_swapix_payment_failure" ){
                    this.msgError = errorPurchase
                } else{
                    this.msgError = "general_error"
                }
            }
        },

        async mounted () {
            this.flagUrl();
            this.purchaseError()
        },
    }
</script>


<style scoped>
    .error .box-error-card{display: flex; flex-direction: column; align-items: center; padding: 20px 0 50px 0;}
    .error .box-error-card img{width: 100px; margin-bottom: 20px;}
    .error .box-error-card h3{color: var(--color-text-secondary); margin: 0;}

    .action{margin-bottom: 50px; display: flex; flex-direction: column; align-items: center;}
    .action .btn-another-method{ background: var(--color-secondary); color: var(--color-text-tertiary); padding: 16px 20px; text-align: center; border-radius: 12px;}
</style>