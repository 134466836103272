<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page v-if="showContent">
            <Header />

            <ion-content class="ion-padding">
                <div class="content">
                    <div class="banner-home">
                        <div class="cont-banner">
                            <h2 class="title-primary">DNE <span>T</span>oken</h2>
                            <p>{{ getMyLanguage("home", "home.content.banner") }}</p>
                            <div @click="lnkToken()" class="btn-primary">{{ getMyLanguage("home", "home.content.banner.cta") }}</div>
                        </div>
                        <img src="@/assets/images/home/banner-dne-token-purchase.jpg">
                    </div>


                    <div class="home-content">
                        <div @click="lnkWallets()" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-dne-token.svg">
                                <h4>DNE <span>T</span>oken</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>
                        
                        <div @click="lnkMarket()" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-market.svg">
                                <h4><span>M</span>arket</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>

                        <div @click="lnkPay()" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-pay.svg">
                                <h4><span>P</span>ay</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>
                        
                        <div @click="lnkBank()" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-bank.svg">
                                <h4>{{getMyLanguage("dne-bank","dne-bank.title")}}</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>

                         <div @click="lnkExchange()" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-exchange.svg">
                                <h4><span>E</span>xchange</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>
                    </div>
                </div>
            </ion-content>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";
    
    import Header from '@/components/dneplatform/Header.vue';
    import Footer from '@/components/dneplatform/Footer.vue';

    import api from '@/config/apiInternational.js';

    export default {
        components: {
            IonApp, IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                flagSelected: {},
                dataProfile: {},
                showContent: false
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            lnkToken(){
                window.location.href = this.flagSelected + '/international/token/'
            },
            lnkWallets(){
                window.location.href = this.flagSelected + '/international/activity/'
            },
            lnkExchange(){
                window.location.href = this.flagSelected + '/dneexchange/'
            },
            lnkBank(){
                window.location.href = this.flagSelected + '/dnebank/'
            },
            lnkMarket(){
                window.location.href = this.flagSelected + '/dnemarket/'
            },
            lnkPay(){
                window.location.href = this.flagSelected + '/dnepay/'
            },
        },


        async mounted () {
            this.flagUrl();

            await api.get('/api/v1/platform/account/profile/')
            .then(response => {
                this.dataProfile = response.data

                const aux = JSON.stringify(this.dataProfile)
                localStorage.setItem("profile",aux)

                this.showContent = true
            })
            .catch(error => {
                
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                    window.location.href = '/'
                }
            })
        }
    }
</script>


<style scoped>
    ion-content{--background: none;}

    .banner-home{position: relative; margin-bottom: 20px;}
    .banner-home .cont-banner{position: absolute; left: 0; top: 43%; transform: translateY(-50%); padding-left: 24px;}
    .banner-home .cont-banner .title-primary{filter: brightness(0) invert(1); text-align: left; margin-bottom: 6px;}
    .banner-home .cont-banner p{color: var(--color-tertiary); margin-bottom: 0; width: 80%; font-size: 10px;}
    .banner-home .cont-banner .btn-primary{margin-top: 8px; width: 65%; height: 36px; font-size: 14px;}
    .banner-home .cont-banner .btn-secondary{margin-top: 6px;}
    .banner-home img{width: 100%; height: auto;}
    
    .home-content{display: flex; flex-wrap: wrap; justify-content: center; max-width: 600px; position: relative; left: 50%; transform: translateX(-50%); margin-bottom: 30px;}
    .lnk-home{display: flex; justify-content: center; align-items: center; flex-basis: 30.33%; width: 100%; border-radius: 50%; margin: 0 1.5%; position: relative; background: #ffffff; -webkit-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -moz-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -webkit-animation: showScale .4s alternate;}
    .lnk-home .size-cicle{width: 100%; height: auto;}
    .lnk-home .cont-lnk{display: flex; flex-direction: column; justify-content: center; align-items: center; position: absolute; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%);}
    .lnk-home .cont-lnk img{width: auto; height: 30px; margin-bottom: 5px;}
    .lnk-home .cont-lnk h4{margin: 0; font-size: 12px; font-weight: bold; white-space: nowrap; color: var(--color-primary);}
    .lnk-home .cont-lnk h4 span{display: inline-block; position: relative; padding-bottom: 0px; color: var(--color-secondary);}
    .lnk-home .cont-lnk h4 span:after{content: ''; display: block; min-width: 5px; width: 84%; height: 2px; position: absolute; left: 8%; bottom: 0; background: var(--color-secondary);}
    .lnk-home.lnk-home-dne{background: var(--color-secondary);}
    .lnk-home.lnk-home-dne h4{filter: brightness(0) invert(1);}


    @media (min-width: 375px) {
        .lnk-home .cont-lnk img{height: 35px;}
        .lnk-home .cont-lnk h4{font-size: 14px;}

        .banner-home .cont-banner .title-primary{margin-bottom: 12px;}
        .banner-home .cont-banner p{width: 70%;}
    }

    @media (min-width: 410px){
        .lnk-home .cont-lnk img{height: 45px;}
        .lnk-home .cont-lnk h4{font-size: 15px;}
    }

    @media (min-width: 576px){
        .lnk-home .cont-lnk img{height: 70px; margin-bottom: 10px;}
        .lnk-home .cont-lnk h4{font-size: 16px;}

        .banner-home .cont-banner{left: 50px; top: 43%; transform: translateY(-50%);}
        .banner-home .cont-banner .title-primary{margin-bottom: 20px;}
        .banner-home .cont-banner p{font-size: 16px; margin-bottom: 0; width: 60%;}
        .banner-home .cont-banner .btn-primary{margin-top: 30px; width: 40%; height: 46px; font-size: 18px;}
        .banner-home .cont-banner .btn-secondary{margin-top: 10px;}
    }
    
    @media (min-width: 660px){
        .lnk-home .cont-lnk h4{font-size: 18px;}
    }

    .phases{margin-top: 60px; margin-bottom: 40px;}

    /* @media (min-height: 800px){
        .home-content{max-height: 300px; top: 50%; transform: translateY(-50%) translateX(-50%);}
    }

    @media (min-height: 1000px){
        .home-content{max-height: 410px; top: 50%; transform: translateY(-50%) translateX(-50%);}
    } */
    
</style>