<template>
    <ion-app :scroll-events="true" class="vouchers">
        <ion-page>
            <div class="header-step">
                <Header />
            </div>

            <ion-content class="vouchers">
                <div class="content">

                    <div class="ion-padding">

                        <Vouchers />

                    </div>

                </div>
            </ion-content>

            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/dneinternational/Header.vue';
    import Vouchers from '@/components/dneinternational/Vouchers.vue';
    import Footer from '@/components/dneinternational/Footer.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            Header,
            Vouchers,
            Footer
        },
        data () {
            return {
                flagSelected: {},
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        },

        async mounted () {
            this.flagUrl();
        }
        
        // created () {
            
        // }
    }
</script>