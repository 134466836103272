<template>
    <ion-app :scroll-events="true" class="shufti-success">
        <ion-page>
            <ion-content class="pending-content">
                <div class="content">
                    <!-- <Step /> -->
                    <div class="load-blue" v-if="!showContent"></div>

                    <div class="container ion-padding" v-if="showContent">
                        <div class="box">
                            <p>{{getMyLanguage("kyc","kyc.shufti-success")}}</p>
                            <a :href='"/" + flagSelected + "/account/compliance/"' class="btn-primary" target="_parent">{{getMyLanguage("kyc","kyc.shufti-success.btn")}}</a>
                        </div>
                    </div>

                </div>
            </ion-content>
            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        components: {
            IonApp,IonPage, IonContent,
        },
        data () {
            return {
                showContent: false,
                KycSumSub: false,
                kycShuftiPro: false,

                dataKYC: {},
                alert: {
                    initial: true,
                    pending: false
                },
                errorsGet: {
                    dataKYC: false
                },
                
                flagSelected: {}
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        },


        async mounted () {
            this.flagUrl()
            this.showContent = true

            setTimeout(() => {
                const routeData = this.$router.resolve({name: 'Compliance', params: { lang: this.$route.params.lang } });
                window.open(routeData.href, '_parent');
            }, 5000);
        }
        
    }
</script>


<style scoped>
    .ShuftiproIframe{
        width: 100%;
        height: 610px;
    }
    
    .shufti-success{background: transparent; height: 100%;}
    .shufti-success .box{padding: 50px 16px;}
    .shufti-success p{margin-bottom: 30px; font-weight: 600; text-align: center;}
</style>