import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Login from '@/pages/dneplatform/Account/Login.vue'
import Register from '@/pages/dneplatform/Account/Register.vue'
import Home from '@/pages/dneplatform/Home/Home.vue'
import Consent from '@/pages/dneplatform/Account/Consent.vue'
import Compliance from '@/pages/dneplatform/Account/Compliance.vue'
import ComplianceCompany from '@/pages/dneplatform/Account/ComplianceCompany.vue'
import ComplianceSuccess from '@/pages/dneplatform/Account/ComplianceSuccess.vue'
import Profile from '@/pages/dneplatform/Account/Profile.vue'
import ProfileSuccess from '@/pages/dneplatform/Account/ProfileSuccess.vue'
import Security from '@/pages/dneplatform/Security/Security.vue'

import DneBank from '@/pages/DneBank/DneBank.vue'
import DneMarket from '@/pages/DneMarket/DneMarket.vue'
import DnePay from '@/pages/DnePay/DnePay.vue'
import DneExchange from '@/pages/DneExchange/DneExchange.vue'

import KYC from '@/pages/DneInternational/Activity/KYC.vue'
import ShuftiSuccess from '@/pages/DneInternational/Activity/ShuftiSuccess.vue'
import Pending from '@/pages/DneInternational/Activity/Pending.vue'
import Token from '@/pages/DneInternational/Token/Token.vue'
import TokenPayment from '@/pages/DneInternational/Token/TokenPayment.vue'
import TokenPaymentSuccess from '@/pages/DneInternational/Token/TokenPaymentSuccess.vue'
import TokenPaymentSuccessId from '@/pages/DneInternational/Token/TokenPaymentSuccessId.vue'
import TokenPaymentError from '@/pages/DneInternational/Token/TokenPaymentError.vue'
import Wallets from '@/pages/DneInternational/Wallets/Wallets.vue'
import Vouchers from '@/pages/DneInternational/Vouchers/Vouchers.vue'
import Activity from '@/pages/DneInternational/Activity/Activity.vue'

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/en/login/' },
  { path: '/:lang/login', name: 'Login', component: Login },
  { path: '/:lang/platform/register', name: 'Register', component: Register },
  { path: '/:lang/platform/home/', name: 'Home', component: Home },
  { path: '/:lang/account/consent/', name: 'Consent', component: Consent },
  { path: '/:lang/account/compliance/', name: 'Compliance', component: Compliance },
  { path: '/:lang/account/compliance-company/', name: 'ComplianceCompany', component: ComplianceCompany },
  { path: '/:lang/account/compliance-success/', name: 'ComplianceSuccess', component: ComplianceSuccess },
  { path: '/:lang/platform/profile/', name: 'Profile', component: Profile },
  { path: '/:lang/platform/profile-success/', name: 'ProfileSuccess', component: ProfileSuccess },
  { path: '/:lang/platform/security/', name: 'Security', component: Security },
  
  { path: '/:lang/dnebank/', name: 'DneBank', component: DneBank },
  { path: '/:lang/dnemarket/', name: 'DneMarket', component: DneMarket },
  { path: '/:lang/dnepay/', name: 'DnePay', component: DnePay },
  { path: '/:lang/dneexchange/', name: 'DneExchange', component: DneExchange },

  { path: '/:lang/account/kyc/', name: 'KYC', component: KYC },
  { path: '/:lang/account/shufti-success/', name: 'ShuftiSuccess', component: ShuftiSuccess },
  { path: '/:lang/account/pending/', name: 'Pending', component: Pending },
  { path: '/:lang/international/token/', name: 'Token', component: Token },
  { path: '/:lang/international/token-payment/', name: 'TokenPayment', component: TokenPayment },
  { path: '/:lang/international/token-payment-error/', name: 'TokenPaymentError', component: TokenPaymentError },
  { path: '/:lang/payment-success/', name: 'TokenPaymentSuccess', component: TokenPaymentSuccess },
  { path: '/:lang/payment-success/:registerId', name: 'TokenPaymentSuccessId', component: TokenPaymentSuccessId },
  { path: '/:lang/international/commissions/', name: 'Wallets', component: Wallets },
  { path: '/:lang/international/Vouchers/', name: 'Vouchers', component: Vouchers },
  { path: '/:lang/international/activity/', name: 'Activity', component: Activity },


  // { path: '/login', component: () => import ('@/pages/Account/Login.vue') },
  // { path: '/register', component: () => import ('@/pages/Account/Register.vue') },
  // { path: '/home', component: () => import ('@/pages/Home/Home.vue') },
  // {
  //   path: '',
  //   redirect: '/folder/Inbox'
  // },
  // {
  //   path: '/folder/:id',
  //   component: () => import ('@/pages/Folder.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
