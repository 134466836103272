<template>
    <ion-app :scroll-events="true" class="compliance-success">
        <ion-page>
            <div class="header-step">
                <Header />
            </div>

            <ion-content class="ion-padding">
                <div class="content">
                    <div class="container">
                        <h2 class="title-secondary">{{ getMyLanguage("compliance", "compliance.title-success") }}</h2>

                        <div class="box-confirmation">
                            <div class="icon-confirm-compliance">
                                <img src="~@/assets/images/icons/register-success.svg">
                            </div>

                            <div class="msg msg-success" v-html='getMyLanguage("compliance", "compliance.msg-success")'></div>
                            <div class="msg msg-attention">
                                <a :href='"/" + flagSelected + "/international/token/"' class="btn-primary">{{getMyLanguage("compliance","compliance.cta-success")}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </ion-content>
            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/dneplatform/Header.vue';
    import Footer from '@/components/dneplatform/Footer.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            Header,
            Footer
        },
        data () {
            return {
                flagSelected: "en",
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        },


        async mounted () {
            this.flagUrl()
             this.showContent = true
        }
    }
</script>

<style>
    .compliance-success .msg.msg-success p{color: var(--color-text-tertiary) !important;}
    .compliance-success .msg.msg-success p:last-child{margin-bottom: 0;}
</style>

<style scoped>
    .msg{padding: 16px; background: url('~@/assets/images/bg-25.svg') no-repeat; background-size: cover; border-radius: 12px; text-align: center; font-size: 13px; font-family: var(--font-primary-semibold);}
    .msg.msg-success{background: url('~@/assets/images/bg-green.png'); position: relative; z-index: 10;}
    
    .msg.msg-attention{margin: -20px 0 40px 0; background: #ccc; padding: 46px 16px 26px 16px;}
    .msg p{margin: 0;}
    .msg h3{font-size: 28px;}

    .icon-confirm-compliance{display: flex; justify-content: center; margin: 10px 0 30px 0;}
    .icon-confirm-compliance img{width: 120px;}

    .btn-primary{padding: 0 20px; background: var(--color-primary);}

    .btn-cancel-compliance{display: flex; justify-content: center; width: 100%;}
    .btn-cancel-compliance span{display: inline-block; font-size: 14px; color: var(--color-cancel); margin: 30px auto 50px; padding: 0 20px; height: 40px; line-height: 40px; border-radius: 12px; background: rgba(0,0,0,0.05); cursor: pointer; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
</style>