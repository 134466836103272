<template>
    <ion-app :scroll-events="true" class="pending">
        <ion-page>
            <div class="header-step">
                <Header />
            </div>

            <ion-content class="pending-content">
                <div class="content">
                    <!-- <Step /> -->
                    <div class="load-blue" v-if="!showContent"></div>

                    <!-- BOX ERROR -->
                    <div class="container ion-padding" v-if="showContent && errorsGet.dataKYC != false">
                        <!-- {{errorsGet.dataKYC}} -->
                        <div class="box-error">
                            <h3 class="title-primary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                            <p>{{ getMyLanguage("box-error", errorsGet.dataKYC) }}</p>
                            <div class="cod-error">
                                Cód.: {{errorsGet.dataKYC}}
                            </div>
                        </div>
                    </div>

                    <!-- IF SUMSUB -->
                    <div class="container ion-padding" v-if="showContent && KycSumSub && !errorsGet.dataKYC">                
                        <h2 class="title-primary">KYC</h2>
                        <h3 class="alert alert-initial" v-if="alert.initial">{{getMyLanguage("kyc","kyc.alert")}}</h3>
                        <h3 class="alert alert-pending" v-if="alert.pending">{{getMyLanguage("kyc","kyc.alert.pending")}}</h3>
                        
                        <div id="sumsub-websdk-container"></div>
                    </div>

                    <!-- IF SUMSUB -->
                    <div class="container ion-padding" v-if="showContent && kycShuftiPro && !errorsGet.dataKYC">
                        <h2 class="title-primary">KYC</h2>
                        <h3 class="alert alert-initial" v-if="alert.initial">{{getMyLanguage("kyc","kyc.alert")}}</h3>
                        <h3 class="alert alert-pending" v-if="alert.pending">{{getMyLanguage("kyc","kyc.alert.pending")}}</h3>
                        
                        <iframe :src='dataKYC.accessToken' allow="camera" id="Shuftipro-iframe" frameborder="0" scrolling="auto" class="ShuftiproIframe"></iframe>
                    </div>
                </div>
            </ion-content>
            <Footer />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/dneinternational/Header.vue';
    // import Step from '@/components/dneplatform/Step.vue';
    import Footer from '@/components/dneinternational/Footer.vue';

    import snsWebSdk from '@sumsub/websdk';

    import api from '@/config/apiInternational.js';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            Header,
            // Step,
            Footer
        },
        data () {
            return {
                showContent: false,
                KycSumSub: false,
                kycShuftiPro: false,

                dataKYC: {},
                alert: {
                    initial: true,
                    pending: false
                },
                errorsGet: {
                    dataKYC: false
                },
                
                flagSelected: {}
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            
            launchWebSdk(accessToken, applicantEmail, applicantPhone, applicantLanguage, customI18nMessages) {
                const snsWebSdkInstance = snsWebSdk.init(
                        accessToken,
                        // token update callback, must return Promise
                        // Access token expired
                        // get a new one and pass it to the callback to re-initiate the WebSDK
                        () => this.getNewAccessToken()
                    )
                    .withConf({
                        lang: applicantLanguage, //language of WebSDK texts and comments (ISO 639-1 format)
                        // lang: this.flagSelected, //language of WebSDK texts and comments (ISO 639-1 format)
                        email: applicantEmail,
                        phone: applicantPhone,
                        i18n: customI18nMessages, //JSON of custom SDK Translations
                        uiConf: {
                            // customCss: "https://url.com/styles.css"
                            // URL to css file in case you need change it dynamically from the code
                            // the similar setting at Customizations tab will rewrite customCss
                            // you may also use to pass string with plain styles `customCssStr:`
                        },
                    })
                    .withOptions({ addViewportTag: false, adaptIframeHeight: true})
                    // see below what kind of messages WebSDK generates
                    .on('idCheck.stepCompleted', (payload) => {
                        console.log('stepCompleted', payload)
                        // window.location.href = '/' + this.flagSelected + '/account/kyc-success'
                    })
                    .on('idCheck.onError', (error) => {
                        console.log('onError', error)
                    })
                    .on('idCheck.applicantStatus', (status) => {
                        // console.log(status.reviewResult)

                        if ( status.reviewResult.reviewAnswer === "RED" && status.reviewResult.reviewRejectType === "RETRY" ){
                            this.alert.initial = false
                            this.alert.pending = true
                        }

                        if ( status.reviewResult.reviewAnswer === "RED" && status.reviewResult.reviewRejectType === "FINAL" ){
                            window.location.href = "/" + this.flagSelected + "/account/pending"
                        }

                        if ( status.reviewResult.reviewAnswer === "GREEN" ){
                            window.location.href = "/" + this.flagSelected + "/account/compliance"
                        }
                    })
                    // .onMessage((type, payload) => {
                    //     console.log('onMessage', type, payload)
                    // })
                    .build();

                // you are ready to go:
                // just launch the WebSDK by providing the container element for it
                snsWebSdkInstance.launch('#sumsub-websdk-container')
            },

            getNewAccessToken() {
                return Promise.resolve(this.dataKYC.accessToken)// get a new token from your backend
            }
        },


        async mounted () {
            this.flagUrl()

            await api.get('/api/v1/platform/account/internacional/kyc')
            .then(response => {
                this.dataKYC = response.data
                this.modalKYC = true

                if ( this.dataKYC.provider === "ShuftiPro" ){
                    this.kycShuftiPro = true
                } else{
                    this.KycSumSub = true
                }
                
                this.showContent = true
            })
            .catch(error => {
                this.errorsGet.dataKYC = error.response.status
                this.errorsGet.dataKYC = true
                this.showContent = true
            })

            
            this.launchWebSdk(this.dataKYC.accessToken, this.dataKYC.applicantEmail, this.dataKYC.applicantPhoneNumber, this.dataKYC.language)
        }
        
        // created () {
            
        // }
    }
</script>


<style scoped>
    .alert{
        font-family: var(--font-primary-semibold);
        color: var(--color-text-tertiary);
        font-size: 16px;
        display: block;
        text-align: center;
        padding: 20px 16px;
        border-radius: 16px;
        background: var(--color-pending);
    }

    .alert.alert-initial{
        background: url('~@/assets/images/bg-blue.png') no-repeat center;
        background-size: cover
    }

    .alert.alert-error{
        background: url('~@/assets/images/bg-red.png') no-repeat center;
        background-size: cover
    }

    .alert.alert-pending{
        background: url('~@/assets/images/bg-pending.svg') no-repeat center;
        background-size: cover;
    }

    .ShuftiproIframe{
        width: 100%;
        height: 610px;
    }
    
</style>